import { XplanFiler } from './XplanFiler';
import axios from 'axios';
import { APICalls, getPayload, getXplanURL } from './helper';
import { LogError } from '../actions/errorWorker';
import { existingDocNoteScreen, entityCriteriaScreen } from './navigationWorker';
import { handleResponseData } from './helper';

export function fileEmail(data: any) {
    const isNote = data.fileToType === 'note';
    const url = getXplanURL() + (isNote ? APICalls.FileEmail : APICalls.FileEmailtoTask);
    const filer = new XplanFiler(url);
    return isNote ? filer.fileNoteToXplan(data) : filer.fileEmailToXplan(data);
}

export function fileExistingItem(DocId: string, otherData: any) {
    const url = getXplanURL() + APICalls.FileEmail;
    const filer = new XplanFiler(url);
    return filer.fileExistingNoteToXplan(DocId, otherData);

}

export function GetEmailIdentifiers() {
    if (Office?.context?.mailbox?.item) {
        return '?ext_type=OutlookAddInV2&ext_item_id=' + encodeURIComponent(Office.context.mailbox.item['conversationId']);
    }
    return null;
}

export async function FindDocNotes() {
    const ei = GetEmailIdentifiers();
    if (!ei) {
        sessionStorage.setItem('hasDocNote', '0');
        entityCriteriaScreen(false);
        return;
    }

    const errorMessage = 'Failed to retrieve email history.';
    const payload = getPayload(getXplanURL() + APICalls.ExistingDocNote + ei, null, 'GET');

    try {
        
        const response = await axios.post(APICalls.AWSUrl(), payload);
        const data = handleResponseData(response, errorMessage);
        if (data) {
            sessionStorage.setItem('hasDocNote', '1');
            existingDocNoteScreen(data);
        } else {
            sessionStorage.setItem('hasDocNote', '0');
            entityCriteriaScreen(false);
        }
    } catch (error) {
        if (!error.message.includes(errorMessage)) LogError(error, { payload }, 'FindDocNotes');
        sessionStorage.setItem('hasDocNote', '0');
        entityCriteriaScreen(false);
    };
}
