import * as React from 'react';

import { DetailsList, DetailsListLayoutMode, IColumn, Selection, SelectionMode, CheckboxVisibility } from 'office-ui-fabric-react/lib/DetailsList';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { entityCriteriaScreen, toggleLoading } from '../actions/navigationWorker';
import { formatDate } from '../actions/helper';
import { fileExistingItem } from '../actions/FilerWorker';

export interface AppProps {
    hide: boolean;
    data: any[];
}

export interface AppState {
    columns: IColumn[],
    selected: any,
    data: any
}

class ExistingDocNote extends React.Component<AppProps, AppState> {
    private _selection: Selection;

    constructor(props) {
        super(props);

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selected: this._getSelectionDetails()
                });
            }
        });

        this.state = {
            columns: this._getColumns(),
            selected: null,
            data: this._getItems()
        };
    }

    NewDoc = () => {
        entityCriteriaScreen(false);
    }

    private _getItems(): any {
        let items = [];
        if (!this.props.data) {
            return items;
        }

        for (const item of this.props.data) {    
            const subject = `|break|Subject: ${item.subject}`;
            const created = formatDate(new Date(item.created_date?._val));
            const created_string = '|break|Created: ' + created;

            const fieldRow = {
                id: item.docid,
                entityId: item.entity_ids,
                entity_name: item.entity_names + subject + created_string,
                subject: `|break|Subject: ${item.subject}`,
                created

            };

            if (!items.some(x => x.id === fieldRow.id)) items.push(fieldRow);
        }

        return items.sort((a, b) => a.subject.localeCompare(b.subject));
    }

    private _getColumns(): IColumn[] {
        let columns = [];
        let fields = [
            {
                'field': 'entity_name',
                'header': 'Note Details',
                'min': 80,
                'max': 200
            },
        ];

        for (let i = 0; i < fields.length; i++) {
            let field = fields[i];
            let item_column = {
                key: 'col_' + field['field'],
                name: field['header'],
                fieldName: field['field'],
                minWidth: field['min'],
                maxWidth: field['max'],
                isResizable: true
            };
            columns.push(item_column);
        }
        return columns;
    }

    private _getSelectionDetails(): any {
        const selected_items = this._selection.getSelection();
        const selected_entities = [];

        if (selected_items !== null) {
            for (let i = 0, l = selected_items.length; i < l; i++) {
                let docid = (selected_items[i] as any).id;
                let entity_name = (selected_items[i] as any).entity_name;
                let entityId = (selected_items[i] as any).entityId;
                let selected_entity = { id: docid, name: entity_name, entityId: entityId };
                selected_entities.push(selected_entity);
            }
        }
        return selected_entities.length > 0 ? selected_entities : null;
    }

    Select_file_to = async () => {
        toggleLoading(true);
        const selectedDoc = this._getSelectionDetails();

        const otherData = {
            entity_ids: selectedDoc[0].entityId,
            username: localStorage.getItem('loginUserName') || '',
            fileToType: 'note',
            role: sessionStorage.getItem('selected_entity_type') || 'client'
        };

        await fileExistingItem(selectedDoc[0].id, otherData);
    }

    render() {
        if (this.props.hide) return null;

        return (
            <div>
                <p>Please select an existing note you would like to refile the current email to or click on 'New':</p>
                <div style={{ overflow: 'auto', marginBottom: '50px' }} data-is-scrollable='true'>
                    <MarqueeSelection selection={this._selection}>
                        <DetailsList
                            items={this._getItems()}
                            columns={this._getColumns()}
                            setKey='set'
                            onRenderItemColumn={this._renderItemColumn}
                            selectionMode={SelectionMode.single}
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            selectionPreservedOnEmptyClick={true}
                            enterModalSelectionOnTouch={true}
                            checkboxVisibility={CheckboxVisibility.always}
                            isHeaderVisible={true}
                            compact={true}
                            selection={this._selection}
                        />
                    </MarqueeSelection>
                </div>
                <div>
                    <div className='footerButton ms-Grid-col ms-sm6 ms-md6 ms-lg6'>
                        <DefaultButton data-automation-id='btnNew' text='New' onClick={this.NewDoc} />
                    </div>
                    <div className='footerButton ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-u-textAlignRight'>
                        <PrimaryButton data-automation-id='btnUpdate' disabled={!this.state.selected} text='Update' onClick={this.Select_file_to} />
                    </div>
                </div>
            </div>
        );
    }


    private _renderItemColumn(item: any, _index: number, column: IColumn) {
        const fieldContent = item[column.fieldName || ''];
        const break_string = '|break|';
        if (column.key === 'col_entity_name') {
            const split_string = fieldContent.split(break_string);
            return split_string.map(t => <span>{t}<br /></span>);
        } else {
            return <span>{fieldContent}</span>;
        }
    }
}

export default ExistingDocNote;
