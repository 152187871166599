import * as React from 'react';
import { Dropdown, DropdownMenuItemType} from 'office-ui-fabric-react/lib/Dropdown';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { LogError } from '../actions/errorWorker';
import { client_search } from '../actions/entityWorker';
import { throwError,  ExistingCheck, toggleLoading } from '../actions/navigationWorker';
import { getEntityTypes, getEntityTypeText, EntityTypesMap } from '../actions/helper';
import { DatePicker, DayOfWeek, IDatePickerStrings } from 'office-ui-fabric-react/lib/DatePicker';

const DayPickerStrings: IDatePickerStrings = {
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],

    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],

    shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],

    goToToday: 'Go to today',
    prevMonthAriaLabel: 'Go to previous month',
    nextMonthAriaLabel: 'Go to next month',
    prevYearAriaLabel: 'Go to previous year',
    nextYearAriaLabel: 'Go to next year',
    invalidInputErrorMessage: 'Invalid date format.',
    isOutOfBoundsErrorMessage: 'Date is out of bounds, please re-enter'
  };

let entityStatus = [];
export interface AppProps {
   hide: boolean;
}

export interface AppState {
    selected: any,
    toggleClientOnlyFields: any,
    search_entity_name: any,
    selectedEntityStatus: any,
    selectedEntityType: string,
    search_postal_code: string,
    search_dob: string,
    selected_dob: Date | null,
    firstDayOfWeek?: DayOfWeek
}

class EntitySelection extends React.Component<AppProps, AppState> {
    constructor(props) {
        super(props);
        let default_client_status = localStorage.getItem('entity_entity_client_status_default');
        let default_client_status_array = default_client_status === null ? [] : default_client_status.split(',').map(Number);
        this.state = {
            selected: {},
            toggleClientOnlyFields: true,
            search_entity_name: '',
            selectedEntityStatus: default_client_status_array,
            selectedEntityType: 'client-v2',
            search_postal_code: '',
            search_dob: '',
            selected_dob: null,
            firstDayOfWeek: DayOfWeek.Sunday
        };
        this.handleEntityTypeChange = this.handleEntityTypeChange.bind(this);
        this.handleEntityNameChange = this.handleEntityNameChange.bind(this);
        this.handleSelectedEntityStatus = this.handleSelectedEntityStatus.bind(this);
        this.handlePostalCodeChange = this.handlePostalCodeChange.bind(this);
        this.getEntityStatus();
    }

    componentDidMount() {
        if (!sessionStorage.getItem('selected_entity_type')){
            this.handleEntityTypeChange(null, {key: 'client-v2', text: 'Client'});
        }
        else{
            this.handleEntityTypeChange(null, {key: EntityTypesMap(sessionStorage.getItem('selected_entity_type')), text: ''});
        }
    }

    handleEntityTypeChange = (_event, item) => {
        let client_only_fields = true;
        let selected_entity_type = item.key;

        if (item.key === 'client-v2') {
            client_only_fields = false;
            selected_entity_type = 'client';
        } else if (item.key === 'referrer') {
            selected_entity_type = 'referral';
        }

        sessionStorage.setItem('selected_entity_type', selected_entity_type);
        this.setState({
            toggleClientOnlyFields: client_only_fields,
            selectedEntityType: item.key
        });
    }

    handleEntityNameChange = (_event, item) => {
        this.setState({ search_entity_name: item});
    }

    handlePostalCodeChange = (_event, item) => {
        this.setState({ search_postal_code: item});
    }

    handleDobChange = (date: Date | null | undefined) => {
        this._onFormatDate(date);
        this.setState({selected_dob: date});
    }

    _onClick = (): void => {
        this.setState({ selected_dob: null });
        this.setState({ search_dob: '' });
    }

    back_to_start = () => {
        ExistingCheck();
    }

    _onFormatDate = (date: Date): string => {
        let selected_day = '';
        let return_date = '';
        if (date) {
            selected_day = date.getFullYear().toString() +
                (('0' + (date.getMonth() + 1)).slice(-2)).toString() +
                (('0' + (date.getDate())).slice(-2)).toString();
        }
        if (date) {
            return_date =  (('0' + (date.getDate())).slice(-2)).toString() + '/' +
                (('0' + (date.getMonth() + 1)).slice(-2)).toString() + '/' +
                date.getFullYear().toString();
        }
        this.setState({search_dob: selected_day});
        return return_date;
    }

    _onParseDateFromString = (value: string): Date => {
        const date = this.state.selected_dob || new Date();
        const values = (value || '').trim().split('/');
        const day = values.length > 0 ? Math.max(1, Math.min(31, parseInt(values[0], 10))) : date.getDate();
        const month = values.length > 1 ? Math.max(1, Math.min(12, parseInt(values[1], 10))) - 1 : date.getMonth();
        let year = values.length > 2 ? parseInt(values[2], 10) : date.getFullYear();
        if (year < 100) {
          year += date.getFullYear() - (date.getFullYear() % 100);
        }
        return new Date(year, month, day);
    }

    handleSelectedEntityStatus = (_event, item) => {
        const updatedSelectedItem = this.state.selectedEntityStatus ? this.copyArray(this.state.selectedEntityStatus) : [];

        if (item.selected) {
            updatedSelectedItem.push(item.key);
        }
        else {
            // remove the option if it's unchecked
            const currIndex = updatedSelectedItem.indexOf(item.key);
            if (currIndex > -1) {
                updatedSelectedItem.splice(currIndex, 1);
            }
        }
        this.setState({selectedEntityStatus: updatedSelectedItem });
    }

    public copyArray = (array: any[]): any[] => {
        const newArray: any[] = [];
        for (let i = 0; i < array.length; i++) {
            newArray[i] = array[i];
        }
        return newArray;
    }

    validate_criteria = () => {
        let p_code = this.state.search_postal_code;
        let error_message = '';
        if (/^[a-zA-Z0-9]*$/.test(p_code) === false) {
            error_message = 'Special characters are not allowed in the Post Code field.';
        } else if (this.state.selected_dob === undefined) {
            error_message = 'Invalid Date of birth please re-enter.';
        }
        return error_message;
    }

    search = () => {
        try {
            let error_message = this.validate_criteria();
            if (error_message.length === 0) {
                let searchCriteria = {};
                searchCriteria['entityName'] = this.state.search_entity_name;

                if (this.state.search_postal_code !== '') {
                    searchCriteria['pcode'] = this.state.search_postal_code;
                }

                if (this.state.search_dob !== '') {
                    searchCriteria['dob'] = this.state.search_dob;
                }
                toggleLoading(true);
                client_search(searchCriteria, this.state.selectedEntityType, this.state.selectedEntityStatus);
            } else {
                throwError(error_message);
            }
        } catch (error) {
            throwError(error);
            LogError(error, {}, 'search', 'ERROR');
        }
    }

    getEntityStatus = () => {
        let data: any;
        entityStatus = [{ key: 'Header', text: 'Entity Status', itemType: DropdownMenuItemType.Header }];

        data = JSON.parse(localStorage.getItem('entity_entity_client_status_results')) || [];
        for (let i = 0; i < data.length; i++) {
            entityStatus.push({ key: data[i][0], text: data[i][1], itemType: DropdownMenuItemType.Normal });
        }
    }

    public render() {
        let hide_postal_code = localStorage.getItem('hide_postal_code') || true;
        const postal_code_style = hide_postal_code === 'true' || this.state.selectedEntityType !== 'client-v2' ? { display: 'none', marginTop: '10px' } : {marginTop: '5px'};
        let hide_dob = localStorage.getItem('hide_dob') || true;
        const dob_style = hide_dob === 'true' || this.state.selectedEntityType !== 'client-v2' ? { display: 'none', marginTop: '5px' } : {marginTop: '19px'};

        const container = document.getElementById('MainBody');
        if (container) {
            container.style.backgroundImage = '';
        }

        const style = this.state.toggleClientOnlyFields ? { display: 'none', marginTop: '5px' } : {marginTop: '5px'};
        const { firstDayOfWeek } = this.state;
        return (
            <div>
                <p>Please enter criteria to search for {getEntityTypeText(this.state.selectedEntityType)}:</p>
                <Dropdown
                    label='Entity Type'
                    placeholder='Entity Type'
                    selectedKey={[this.state.selectedEntityType]}
                    options = {getEntityTypes([{ key: 'Header', text: 'Entity Type', itemType: DropdownMenuItemType.Header }])}
                    onChange={this.handleEntityTypeChange}/>
                <div id='divEntityStatus' style={style}>
                    <Dropdown
                        label='Entity Status'
                        placeholder='Entity Status'
                        onChange={this.handleSelectedEntityStatus}
                        multiSelect
                        defaultSelectedKeys={this.state.selectedEntityStatus}
                        options={entityStatus}/>
                </div>
                <div id='divName' style={{marginTop: '5px'}}>
                    <TextField
                        label='Name/Surname/Entity ID'
                        autoFocus
                        placeholder='Prefix for Mobile (t: or tel:) or Email (e: or em:)'
                        onChange={this.handleEntityNameChange}
                        onKeyPress={(ev) => {
                            if (ev.key === 'Enter') {
                                this.search();
                                ev.preventDefault();
                            }
                          }}
                        value={this.state.search_entity_name}/>
                </div>
                <div id='divPostCode' style={postal_code_style}>
                    <TextField
                        label='Postal Code'
                        placeholder='Post Code'
                        id='txtPostalCode'
                        onChange={this.handlePostalCodeChange}
                        value={this.state.search_postal_code}/>
                </div>
                <div id='divDateOfBirth' style={dob_style}>
                    <DatePicker
                        label='Date of Birth'
                        firstDayOfWeek={firstDayOfWeek}
                        strings={DayPickerStrings}
                        placeholder='Date of Birth: DD/MM/YYYY'
                        allowTextInput= {true}
                        onSelectDate={this.handleDobChange}
                        value={this.state.selected_dob}
                        parseDateFromString={this._onParseDateFromString}
                        formatDate={this._onFormatDate}
                        maxDate= {new Date()}
                        highlightCurrentMonth= {true}
                        highlightSelectedMonth= {true}
                        disableAutoFocus= {true}
                        showMonthPickerAsOverlay={true}/>
                </div>
                <div id='divClearDate' style={dob_style}>
                    <DefaultButton style={this.state.selectedEntityType !== 'client-v2' ? { display: 'none' } : {}} onClick={this._onClick} text='Clear Date' />
                </div>
                <br />
                <div className='ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-u-textAlignLeft'>
                    <DefaultButton data-automation-id='btnBack' text='Existing Notes' onClick={ this.back_to_start }
                        style={sessionStorage.getItem('hasDocNote') !== '1' ? { display: 'none' } : {}}/>
                        &nbsp;&nbsp;&nbsp;
                </div>
                <div className='ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-u-textAlignRight'>
                    <PrimaryButton data-automation-id='btnNext' text='Search' onClick={this.search}/>
                </div>
                &nbsp;&nbsp;&nbsp;
            </div>
        );
    }
}

export default EntitySelection;
