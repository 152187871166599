import * as React from 'react';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { ExistingCheck } from '../actions/navigationWorker';

type Props = {
    link?: string;
    message?: string;
    linkTitle?: string;
    hide?: boolean
}

function SuccessMessage({ message, link, linkTitle }: Props) {
    const back_to_start = () => {
        ExistingCheck();
    }

    const footer = (
        <div>
            <div className='ms-Grid-col ms-sm6 ms-md6 ms-lg6'>
                &nbsp;
            </div>
            <br />
            <div className='footerButton ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-u-textAlignCentre'>
                <DefaultButton data-automation-id='btnFileAgain' text='File Again' onClick={back_to_start} />
            </div>
        </div>
    );

    if (link && message) {
        return (
            <div className='ms-u-textAlignCenter'>
                <br /><br />
                <img src='assets/success.png'></img>
                <br /><br />
                {message}
                <br /><br />
                Click on the link below to view in Xplan:
                <br /><br />
                <a href={link} target='_blank' >{linkTitle}</a>
                <br />
                {footer}
            </div>
        );
    }

    if (message) {
        return (
            <div className='ms-u-textAlignCenter'>
                <br /><br />
                <img src='assets/success.png'></img>
                <br /><br />
                {message}
                <br />
                {footer}
            </div>
        );
    }

    if (link) {
        return (
            <div className='ms-u-textAlignCenter'>
                <br /><br />
                <img src='assets/success.png'></img>
                <br /><br />
                The email has been successfully filed to Xplan.
                <br /><br />
                Click on the link below to view in Xplan:
                <br /><br />
                <a href={link} target='_blank' >{linkTitle}</a>
                <br />
                {footer}
            </div>
        );
    }

    return (
        <div className='ms-u-textAlignCenter'>
            <br /><br />
            <img src='assets/success.png'></img>
            <br /><br />
            The email has been successfully filed to Xplan.
            <br />
            {footer}
        </div>
    );
}

export default SuccessMessage;
