import axios from "axios";
import { errorScreen, renderActiveTaskScreen, throwError, toggleLoading } from "./navigationWorker";
import { getXplanURL, APICalls, getPayload, handleResponseData } from "./helper";
import { LogError } from "./errorWorker";

export async function get_activetask_props(hide) {
	const selected_clients = JSON.parse(sessionStorage.getItem("selected_clients")) || [];

	toggleLoading(true);
	if (!hide) {
		let entity_ids = "";
		const data = [];

		if (selected_clients?.length > 1) {
			return errorScreen(
				false,
				"",
				"",
				"Filing to multiple entities on active tasks is not permitted.",
				{
					hideRetry: true,
					hideTitle: true,
					backTitle: "Back",
				}
			);
		}

		for (let i = 0; i < selected_clients.length; i++) {
			entity_ids = "&entity_ids.0=" + selected_clients[i]["id"];
			const payload = getPayload(
				getXplanURL() + APICalls.ActiveTasks.replace("{2}", entity_ids),
				null,
				"GET"
			);

			await axios
				.post(APICalls.AWSUrl(), payload)
				.then((response) => {
					const result_data = handleResponseData(
						response,
						"No Active Task found for this client",
						true
					);

					const isError = result_data && result_data.isError;
					if (isError) {
						throw result_data.message;
					}

					if (result_data) data.push(...result_data);

					if (!data.length) return renderActiveTaskScreen([], false);

					if (i === selected_clients.length - 1 && !isError && isError !== null) {
						process_data(data);
					}
				})
				.catch((error) => {
					throwError(error);
					LogError(error, { payload }, "get_activetask_props", "ERROR");
				});
		}
	} else {
		renderActiveTaskScreen([], hide);
	}
}

export function process_data(data) {
	let task_assignee_params = "";
	const task_assignee_list = [];
	let counter = 0;

	for (let item_count = 0; item_count < data.length; item_count++) {
		const task_assignee_id = data[item_count]["assignee"];
		if (task_assignee_list.indexOf(task_assignee_id) < 0) {
			task_assignee_params =
				task_assignee_params + "&ids." + counter + "=" + task_assignee_id;
			task_assignee_list.push(task_assignee_id);
			counter = counter + 1;
		}
	}
	get_adviser_details(task_assignee_params, data);
}

export function get_adviser_details(entity_ids, task_data) {
	const items = [];
	const taskkind_lookup = JSON.parse(localStorage.getItem("task_taskkind_results")) || [];
	const subtype_lookup = JSON.parse(localStorage.getItem("task_subtype_results")) || [];

	const payload = getPayload(getXplanURL() + APICalls.AdviserSearch + entity_ids, null, "GET");
	axios
		.post(APICalls.AWSUrl(), payload)
		.then((response) => {
			const adviser_data = handleResponseData(response, "");
			const clients = JSON.parse(sessionStorage.getItem("selected_clients"));
			for (let item_count = 0; item_count < task_data.length; item_count++) {
				let task_due_date = task_data[item_count]["duedate"];
				task_due_date = task_due_date ? task_due_date._val : "";

				const task_type_id = task_data[item_count]["taskkind"];
				const task_subtype_id = task_data[item_count]["subtype"];
				const task_assignee_id = task_data[item_count]["assignee"];

				let task_type = taskkind_lookup.find((element) => {
					return element[0] === task_type_id;
				});
				if (task_type) {
					task_type = task_type[1];
				}
				let task_subtype = subtype_lookup.find((element) => {
					return element[0] === task_subtype_id;
				});
				if (task_subtype) {
					task_subtype = task_subtype[1];
				}
				let task_assignee;
				if (adviser_data) {
					task_assignee = adviser_data.find((element) => {
						return element.id === task_assignee_id;
					});
					if (task_assignee) {
						task_assignee = task_assignee.entity_name;
					}
				}

				const task_subject = task_data[item_count]["subject"];
				const task_id = task_data[item_count]["id"];
				const entity = clients.find((x) => x.id === task_data[item_count]["client"]);
				const entityName = entity.name || "";
				const fieldRow = {
					id: task_id,
					subject: task_subject,
					type: task_type,
					subtype: task_subtype,
					due_date: task_due_date,
					assignee: task_assignee,
					name: entityName,
					details:
						entityName +
						"|break|Task ID: " +
						task_id +
						"|break|Subject: " +
						task_subject +
						"|break|Type: " +
						task_type +
						" > " +
						task_subtype +
						"|break|Due Date: " +
						task_due_date +
						"|break|Assignee: " +
						task_assignee,
				};
				items.push(fieldRow);
			}

			renderActiveTaskScreen(items, false);
		})
		.catch((error) => {
			throwError(error);
			LogError(error, { payload: payload }, "get_adviser_details", "ERROR");
		});
}
