import axios from "axios";
import { NotificationManager } from "react-notifications";
import {
	APICalls,
	getPayload,
	handleResponseData,
	setXplanCookie,
	getDeploymentId,
	updateCredentials,
} from "./helper";
import { LogError } from "../actions/errorWorker";
import {
	failed_login,
	toggleLoading,
	renderLogin,
	renderLogo,
	ExistingCheck,
} from "../actions/navigationWorker";
import {
	get_ufield_values,
	get_choice_field_dependence,
	get_capabilities,
} from "../actions/entityWorker";
import { get_docnote_props } from "../actions/docpropWorker";
import { clearLogin } from "../actions/helper";
import { getUserFieldDefaults } from "../actions/backgroundWorker";

type Fn = (...args) => any | void;
type AppScriptParams = {
	xplan_cookie: string;
	entity_id: number | string;
	entity_name: string;
	errorFun?: Fn;
	access_token: string;
	scriptId: string;
	environment: string;
};

async function successLogin(data: any) {
	NotificationManager.success("Logged in : " + data.entity_name, "Welcome", 2500);
	if (data.entity_id) localStorage.setItem("loginUserId", data.entity_id);
	if (data.entity_name) localStorage.setItem("loginUserName", data.entity_name);
	if (data.user_id) localStorage.setItem("user_name", data.user_id);

	await Promise.all([GetUfieldData(true), getUserFieldDefaults()]);
}

function getAuth(username: string, password: string) {
	const creds = `${username}:${password}`;
	return `Basic ${window.btoa(creds)}`;
}

function errorLogin(error) {
	NotificationManager.error(error.message, "Invalid", 5000);
	clearLogin();
	sessionStorage.clear();
	failed_login("Incorrect credentials or URL");
}

export async function GetUfieldData(loadUFields: boolean = false): Promise<void> {
	const promises: Promise<void>[] = [
		get_ufield_values("entity", "entity_client_status", loadUFields),
		get_ufield_values("entity_note", "type", loadUFields),
		get_ufield_values("entity_note", "subtype", loadUFields),
		get_ufield_values("task", "taskkind", loadUFields),
		get_ufield_values("task", "subtype", loadUFields),
		get_ufield_values("task", "status", loadUFields),
		get_choice_field_dependence("entity_note", "subtype", "", null, loadUFields),
		get_choice_field_dependence("task", "subtype", "", null, loadUFields),
		get_docnote_props(),
	];

	await Promise.all(promises);
}

export function getParameterByName(name: string, url?: string) {
	if (!url) {
		url = window.location.href;
	}
	name = name.replace(/[\[\]]/g, "\\$&");
	const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
	const results = regex.exec(url);

	if (!results) return null;

	if (!results[2]) return "";

	return decodeURIComponent(results[2].replace(/\+/g, " "));
}

async function makeCall(
	url: string,
	successFunc: (...args) => Promise<void>,
	errorFunc: Fn,
	auth: string
) {
	const payload = getPayload(url, auth, "GET");
	const access_token = getParameterByName("useruniqueaccesstoken", null);
	const scriptId = getParameterByName("scriptID", null);

	if (payload["cookie"] && access_token && scriptId) {
		sendCookieToAppScript({
			xplan_cookie: payload["cookie"],
			entity_id: localStorage.getItem("loginUserId"),
			entity_name: localStorage.getItem("xplan_user_name"),
			access_token,
			scriptId,
			environment: getParameterByName("environment", null) ?? "",
			errorFun: errorFunc,
		});
	}

	if (payload["cookie"]) {
		await Promise.all([GetUfieldData(), getUserFieldDefaults()]);
		successFunc(HasCapability, ["file_email_as_docnote", "file_email_as_task"]);
		return;
	}

	const tfaCookie = localStorage.getItem("tfa_cookie");
	if (tfaCookie) payload.cookie = tfaCookie;

	axios
		.post(APICalls.AWSUrl(), payload)
		.then(async (response) => {
			const data = handleResponseData(response, "");
			if (response.data.isError) {
				if (data && data["IsTFA"]) {
					NotificationManager.info("Please enter a valid OTP", "OTP Required", 5000);
					renderLogin(false, false, "Please enter One Time Pin", true);
				} else {
					throw response.data.statusdesc;
				}
			} else if ("xplan_cookie" in response.data) {
				if (access_token && scriptId) {
					const responseData = JSON.parse(response.data.data);
					sendCookieToAppScript({
						xplan_cookie: response.data.xplan_cookie,
						entity_id: responseData.entity_id,
						entity_name: responseData.entity_name,
						access_token,
						scriptId,
						errorFun: errorFunc,
						environment: getParameterByName("environment", null) ?? "",
					});
				} else {
					setXplanCookie(response.data["xplan_cookie"]);
					await successLogin(data);
					successFunc(HasCapability, ["file_email_as_docnote", "file_email_as_task"]);
				}
			}
		})
		.catch((error) => {
			console.log("error", error);
			errorLogin(error);
			if (errorFunc) errorFunc();
			LogError(error, { payload: payload }, "makeCall", "ERROR");
		});
}

export function loginUser(
	url: string,
	username: string,
	password: string,
	otp: string,
	successFun,
	errorFun
) {
	toggleLoading(true);
	if (otp) password = `${password}\n\r\t\u0007${otp}`;
	if (username) localStorage.setItem("xplan_user_name", username);

	const auth = getAuth(username, password);
	makeCall(url, successFun, errorFun, auth);
	return;
}

export async function checkValidCookie(url: string) {
	toggleLoading(true);
	const payload = getPayload(url, null, "GET");

	await axios
		.post(APICalls.AWSUrl(), payload)
		.then(async (response) => {
			if (!response.data.isError) {
				const data = JSON.parse(response.data.data);
				updateCredentials(response, data);
				loginUser(url, null, null, null, get_capabilities, renderLogin);
			} else {
				localStorage.removeItem("xplan_cookie");
				localStorage.removeItem("xplan_cookie_expire");
				renderLogo(false, true);
				renderLogin(null, true);
				toggleLoading(false);
			}
		})
		.catch((error) => {
			LogError(error, { payload: payload }, "checkValidCookie", "ERROR");
		});
}

export function HasCapability(capability) {
	const capabilities: string[] = JSON.parse(localStorage.getItem("capabilities_results")) || [];
	let has_caps = false;
	const caps = typeof capabilities === "string" ? [capability] : capability;

	for (const cap of caps) {
		if (capabilities.includes(cap)) {
			has_caps = true;
			break;
		}
	}

	if (has_caps) ExistingCheck();
	else failed_login("You do not have the correct capabilities");
}

export function sendCookieToAppScript({
	xplan_cookie,
	entity_id,
	entity_name,
	errorFun,
	access_token,
	scriptId,
	environment,
}: AppScriptParams) {
	const xp_site = localStorage.getItem("xplan_url") || "";
	const bearer_token = "Bearer " + access_token;
	const isDevEnvironment = !["production", "staging"].includes(environment);
	const deploymentId = isDevEnvironment ? scriptId : getDeploymentId(environment);

	const gmail_payload = {
		gmail_header: {
			"Access-Control-Allow-Origin": "*",
			Authorization: bearer_token,
			Accept: "application/json",
		},
		gmail_params: {
			function: "receiveCookieToAppScript",
			parameters: [xplan_cookie, xp_site, entity_id, entity_name],
			devMode: isDevEnvironment,
		},
	};

	const url = `https://script.googleapis.com/v1/scripts/${deploymentId}:run`;
	const payload = getPayload(url, null, "POST");
	payload["target"] = "gmail";
	payload["gmail_payload"] = gmail_payload;
	axios
		.post(APICalls.AWSUrl(), payload)
		.then(async (_res) => {
			close();
		})
		.catch((error) => {
			LogError(error, { payload: payload, config: {} }, "sendCookieToAppScript", "ERROR");
			errorLogin(error);
			if (errorFun) errorFun();
		});
}
