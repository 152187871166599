import * as React from 'react';

import { DetailsList, DetailsListLayoutMode, IColumn, Selection, SelectionMode, CheckboxVisibility } from 'office-ui-fabric-react/lib/DetailsList';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { entityCriteriaScreen, fileToTypeScreen, toggleLoading } from '../actions/navigationWorker';
import { getEntityTypeText } from '../actions/helper';

export interface AppProps {
    hide: boolean;
    data: Array<JSON>;
}

export interface AppState {
    columns: IColumn[],
    selected: any,
    data: any
}

class SearchResults extends React.Component<AppProps, AppState> {
    private _selection: Selection;

    constructor(props) {
        super(props);

        this._selection = new Selection({
          onSelectionChanged: () => {
            this.setState({
              selected: this._getSelectionDetails()
            });
          }
        });

        this.state = {
            columns: this._getColumns(),
            selected: null,
            data: this.makeRows()
        };
    }

    Back_to_Criteria = () => {
        this.setState({
            data: null
          });
        entityCriteriaScreen(true);
    }

    private makeRows() {
        if (this.props.data.length === 0) {
            return null;
        }
        let items = [];
        let entity_type = sessionStorage.getItem('selected_entity_type') || '';
        if (entity_type === 'client') {
            for (let item_count = 0; item_count < this.props.data.length; item_count++) {

                let entity_string = this.props.data[item_count]['entity_name'];
                let adviser_string = '|break|Adviser: ' + this.props.data[item_count]['fields']['client_adviser_name'];
                let dob = this.props.data[item_count]['fields'].dob ? this.props.data[item_count]['fields'].dob._val : '';
                let dob_string = '|break|Date of Birth: ' + dob.toString();
                let postcode_string = '|break|Post Code: ' + this.props.data[item_count]['fields'].preferred_postcode.toString();
                let new_string = entity_string + adviser_string + dob_string + postcode_string;

                let fieldRow = {
                    id: this.props.data[item_count]['id'],
                    entity_name : entity_string,
                    client_detail : new_string
                   
                };
                items.push(fieldRow);
            }
            
        }
        else{
            for (let item_count = 0; item_count < this.props.data.length; item_count++) {
                let fieldRow = {
                    id: this.props.data[item_count]['id'],
                    entity_name: this.props.data[item_count]['entity_name'],
                    client_detail: this.props.data[item_count]['entity_name'],
                };
                items.push(fieldRow);
            }
        }
        return items;
    }

    private _getColumns(): IColumn[] {
       
        let columns = [];
        let fields = [{
            'field': 'client_detail',
            'header': 'Client Details',
            'min': 80,
            'max': 200
        }];
   

        for (let i = 0; i < fields.length; i++) {
            let field = fields[i];
            let item_column = {
                key: 'col_' + field['field'],
                name: field['header'],
                fieldName: field['field'],
                minWidth: field['min'],
                maxWidth: field['max'],
                isResizable: true
            };
            columns.push(item_column);
        }
        return columns;
    }

    private _getSelectionDetails(): any {
        const selected_items = this._selection.getSelection();
        const selected_entities = [];

        if (selected_items !== null) {
            for (let i = 0; i < selected_items.length; i++) {
                let entity_id = (selected_items[i] as any).id;
                let entity_name = (selected_items[i] as any).entity_name;
                let selected_entity = {id: entity_id, name: entity_name};
                selected_entities.push(selected_entity);
            }
        }
        return selected_entities.length > 0 ? selected_entities : null;
    }

    Select_file_to = () => {
        toggleLoading(true);
        const eIds = this._getSelectionDetails();
        sessionStorage.setItem('selected_clients', JSON.stringify(eIds));
        fileToTypeScreen(false);
    }

    private _renderItemColumn(item: any, _index: number, column: IColumn) {
        const fieldContent = item[column.fieldName || ''];
        const break_string = '|break|';
        if (column.key === 'col_client_detail') {
            const split_string = fieldContent.split(break_string);
            return split_string.map(t => <span>{t}<br/></span>);
        } else {
            return <span>{fieldContent}</span>;
        }
    }

    render() {
        return (
            <div>
                <p>Please select the {getEntityTypeText(sessionStorage.getItem('selected_entity_type'))}(s) that you would like to file the current email to:</p>
                <div style={{overflow: 'auto', marginBottom: '10px'}} data-is-scrollable='false'>
                    <MarqueeSelection selection={this._selection}>
                        <DetailsList
                            items={this.state.data}
                            columns={this._getColumns()}
                            setKey='set'
                            selectionMode={SelectionMode.multiple}
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            selectionPreservedOnEmptyClick={true}
                            enterModalSelectionOnTouch={true}
                            checkboxVisibility={CheckboxVisibility.always}
                            isHeaderVisible={true}
                            compact={true}
                            selection={this._selection}
                            onRenderItemColumn={this._renderItemColumn}
                        />
                    </MarqueeSelection>
                </div>
                <div className='ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-u-textAlignRight'>
                    <PrimaryButton data-automation-id='btnNext' disabled={!this.state.selected} text='Next' onClick={ this.Select_file_to } />
                </div>
            </div>
        );
    }
}

export default SearchResults;
