import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import { start, renderSplash } from "./actions/navigationWorker";
import { getParameterByName } from "./actions/loginWorker";
import "office-ui-fabric-react/dist/css/fabric.min.css";
import "../function-file/function-file";
import { getAppConfig } from "./appConfig";

initializeIcons();

function loadSplash(): void {
	renderSplash(false);
}

loadSplash();

Office.initialize = (): void => {};

Office.onReady().then(() => {
	start(getAppConfig());
});

// This runs for gmail plugin
const access_token = getParameterByName("useruniqueaccesstoken", null);
const scriptId = getParameterByName("scriptID", null);

if (access_token && scriptId) {
	start(getAppConfig());
}
