import * as React from "react";
import { ChoiceGroup } from "office-ui-fabric-react/lib/ChoiceGroup";
import {
	notesScreen,
	activeTaskScreen,
	newTaskScreen,
	errorScreen,
} from "../actions/navigationWorker";
import { has_capability } from "../actions/entityWorker";

export interface AppProps {
	hide: boolean;
}

export interface AppState {
	selectedFileTo: string;
}

class FileEmailTo extends React.Component<AppProps, AppState> {
	constructor(props) {
		super(props);
		const file_type = JSON.parse(sessionStorage.getItem("file_to_type")) || { id: "note" };

		this.state = {
			selectedFileTo: file_type.id,
		};

		let file_to_name = "";
		if (file_type.id === "note") {
			file_to_name = "Note";
			notesScreen(false);
		} else if (file_type.id === "activetask") {
			file_to_name = "Active Task";
			activeTaskScreen(false);
		} else if (file_type.id === "newtask") {
			file_to_name = "New Task";
			newTaskScreen(false);
		}

		if (file_to_name !== "") {
			this.state = { selectedFileTo: file_type.id };
			sessionStorage.setItem(
				"file_to_type",
				JSON.stringify({ id: file_type.id, name: file_to_name })
			);
		}
		this.handleFileToTypeChange = this.handleFileToTypeChange.bind(this);
	}

	handleFileToTypeChange = async (_event, item) => {
		errorScreen(true, "", "", "");
		if (item.key === "note") {
			notesScreen(false);
		} else if (item.key === "activetask") {
			activeTaskScreen(false);
		} else if (item.key === "newtask") {
			newTaskScreen(false);
		}

		this.setState({ selectedFileTo: item.key });
		sessionStorage.setItem("file_to_type", JSON.stringify({ id: item.key, name: item.text }));
	};

	public render() {
		if (this.props.hide) {
			return null;
		}
		const file_type = JSON.parse(sessionStorage.getItem("file_to_type")) || { id: "note" };
		const file_to_options = [];

		if (has_capability("edit_entity_note") && has_capability("file_email_as_docnote")) {
			file_to_options.push({ key: "note", text: "Note" });
		}
		if (has_capability("edit_tasks") && has_capability("file_email_as_task")) {
			file_to_options.push({ key: "newtask", text: "New Task" });
			file_to_options.push({ key: "activetask", text: "Active Task" });
		}

		return (
			<div>
				<p>Please select a filing option:</p>
				<ChoiceGroup
					options={file_to_options}
					selectedKey={file_type.id}
					onChange={this.handleFileToTypeChange}
					defaultSelectedKey={this.state.selectedFileTo}
				/>
				<br />
			</div>
		);
	}
}

export default FileEmailTo;
