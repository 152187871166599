import {
	APICalls,
	getAppClientID,
	getEnvironment,
	getPayload,
	getRedirectURL,
	getXplanTokenURL,
	getXplanURL,
	handleResponseData,
	httpMethods,
} from "./helper";
import axios from "axios";
import { getParameterByName, sendCookieToAppScript } from "./loginWorker";
import { LogError } from "../actions/errorWorker";

export async function getUserFieldDefaults() {
	const payload = getPayload(`${getXplanURL()}${APICalls.UserFieldDefaults}`);
	const response = await axios.post(APICalls.AWSUrl(), payload);
	if (response.data && response.data.isError === false) {
		const data = handleResponseData(response, "");
		if (data && Object.keys(data).length > 0) {
			//Set Defaults
			localStorage.setItem("entity_entity_client_status_default", data["entity_status"]);
			localStorage.setItem("entity_note_type_default", data["note_type"]);
			localStorage.setItem("entity_note_subtype_depend_default", data["note_subtype"]);
			localStorage.setItem("Permission_default", data["note_permission"]);
			localStorage.setItem("task_type_default", data["task_type"]);
			localStorage.setItem("task_subtype_depend_default", data["task_subtype"]);
			localStorage.setItem("task_status_default", data["task_status"]);
			localStorage.setItem("skip_summary", data["skip_summary_page"]);
			localStorage.setItem("hide_postal_code", data["hide_postal_code"]);
			localStorage.setItem("hide_dob", data["hide_dob"]);
		}
	}

	DeleteFileS3(localStorage.getItem("user_story_session_guid"));
}

export function getCustomURLS(): void {
	const payload = getPayload(`${getXplanURL()}${APICalls.FileToURL}`, null, httpMethods.Get);
	axios
		.post(APICalls.AWSUrl(), payload)
		.then((response) => {
			const data = handleResponseData(response, "");
			setCustomPage("custom_note_url", data["note_url"]);
			setCustomPage("custom_task_url", data["task_url"]);
			return;
		})
		.catch((error) => {
			import("../actions/errorWorker").then((errorWorker) => {
				errorWorker.LogError(error, { payload: payload }, "getCustomURLS", "ERROR");
			});
		});
}

function setCustomPage(key: string, url: string): void {
	if (url && url.indexOf("page=") >= 0) {
		localStorage.setItem(key, url.slice(url.indexOf("page=") + 5));
	} else {
		localStorage.removeItem(key);
	}
}

export function logoutXplan(): void {
	const payload = getPayload(`${getXplanURL()}${APICalls.LogoutXplan}`, null, httpMethods.Delete);
	axios.post(APICalls.AWSUrl(), payload);
	return;
}

export function confirmAuth(auth_code: string) {
	const access_token = getParameterByName("useruniqueaccesstoken", null);
	const scriptId = getParameterByName("scriptID", null);
	return new Promise((resolve, reject) => {
		const payload = getPayload(getXplanTokenURL(), null, httpMethods.Post);
		payload.data["client_id"] = getAppClientID();
		payload.data["environment"] = getEnvironment();
		payload.data["redirect_uri"] = getRedirectURL();
		payload.data["grant_type"] = "authorization_code";
		payload.data["code"] = auth_code;
		payload.target = "XPLANSSO";

		axios
			.post(APICalls.AWSUrl(), payload)
			.then((response) => {
				const data = handleResponseData(response, "");

				LogError(new Error("confirmAuthResponse"), data, "confirmAuthResponse", "INFO");

				if (data) {
					localStorage.setItem("auth_code", data.auth_code);
					localStorage.setItem("access_token", data.access_token); // Access token expires in one hour.
					localStorage.setItem("refresh_token", data.refresh_token); // Refresh token that can be used to exchange for a new access token
					localStorage.setItem("auth_token_type", data.token_type);
					localStorage.setItem("expires_in", data.expires_in);
					if (response.data.xplan_cookie && access_token && scriptId) {
						sendCookieToAppScript({
							xplan_cookie: response.data.xplan_cookie,
							entity_id: localStorage.getItem("loginUserId"),
							entity_name: localStorage.getItem("xplan_user_name"),
							access_token,
							scriptId,
							environment: getParameterByName("environment", null) ?? "",
						});
					}
					resolve(true);
				} else {
					localStorage.removeItem("access_token");
					localStorage.removeItem("refresh_token");
					localStorage.removeItem("auth_token_type");
					reject("No Data Recieved");
				}
			})
			.catch((err) => {
				const data = err?.response?.data || {};
				LogError(err, { payload: data, details: err?.toJSON() }, "confirmAuth");
				reject(err);
			});
	});
}

export function DeleteFileS3(uss_guid: string) {
	const payload = getPayload(getXplanTokenURL(), null, httpMethods.Post);
	payload.target = "DeleteFileS3";
	payload.user_story_session_guid = uss_guid;
	axios.post(APICalls.AWSUrl(), payload);
}
