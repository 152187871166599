import * as React from "react";
import { failed_login, RefreshFieldsScreen } from "../actions/navigationWorker";
import { IconButton } from "office-ui-fabric-react/lib/Button";
import { slide as Menu } from "react-burger-menu";
import { cleanup } from "../actions/helper";

class Logo extends React.Component<any, any> {
	constructor(props, context) {
		super(props, context);
		this.state = {
			menuOpen: false,
		};
	}

	Logout = () => {
		this.CloseMenu();
		failed_login("", true);
		localStorage.removeItem("is_sso_login");
		localStorage.removeItem("user_name");
	};

	Settings = () => {
		this.CloseMenu();
		RefreshFieldsScreen();
	};

	Home = () => {
		this.CloseMenu();
		location.reload();
	};

	HandleStateChange = (state) => {
		this.setState({ menuOpen: state.isOpen });
	};

	CloseMenu = () => {
		this.setState({ menuOpen: false });
	};

	render() {
		if (this.props.hide || this.props.login) {
			return null;
		}
		let source = "assets/header_logo.png";
		if (this.props.login) {
			source = "";
		}

		const burger_styles = {
			bmBurgerButton: {
				position: "fixed",
				width: "24px",
				height: "24px",
				right: "24px",
				top: "29px",
			},
			bmBurgerBars: {
				background: "#ffffff",
			},
			bmBurgerBarsHover: {
				background: "#a90000",
			},
			bmCrossButton: {
				height: "24px",
				width: "24px",
			},
			bmCross: {
				background: "#bdc3c7",
			},
			bmMenuWrap: {
				position: "fixed",
				height: "100%",
			},
			bmMenu: {
				background: "#373a47",
				padding: "2.5em 1em 0",
				fontSize: "1.15em",
			},
			bmMorphShape: {
				fill: "#373a47",
			},
			bmItemList: {
				color: "#b8b7ad",
				padding: "0.8em",
				height: "90%",
				width: "fit-content",
			},
			bmItem: {
				display: "inline-block",
				color: "#ffffff",
				textDecoration: "none",
				cursor: "pointer",
			},
			bmOverlay: {
				background: "rgba(0, 0, 0, 0.3)",
			},
		};

		const style = { height: "65px" };
		const span_style = { padding: "0.3em 0 0 0.5em", display: "inline-flex" };
		let loggedIn_style = { display: "none" };
		if (!this.props.login) {
			loggedIn_style = { display: "inline-block" };
		}

		return (
			<div style={style}>
				<div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-u-textAlignCenter">
					<img src={source} onDoubleClick={cleanup}></img>
				</div>
				<Menu
					disableAutoFocus
					right
					width={"260px"}
					styles={burger_styles}
					isOpen={this.state.menuOpen}
					onStateChange={(state) => this.HandleStateChange(state)}
				>
					<a id="home" className="bmItem" style={loggedIn_style} onClick={this.Home}>
						<IconButton
							data-automation-id="btnHome"
							style={{ color: "white" }}
							iconProps={{ iconName: "Home" }}
						/>
						<span style={span_style}>Home</span>
					</a>
					<br />

					<a
						id="settings"
						className="bmItem"
						style={loggedIn_style}
						onClick={this.Settings}
					>
						<IconButton
							data-automation-id="btnSettings"
							style={{ color: "white" }}
							iconProps={{ iconName: "Settings" }}
						/>
						<span style={span_style}>Settings</span>
					</a>
					<br />

					<a id="logout" className="bmItem" style={loggedIn_style} onClick={this.Logout}>
						<IconButton
							data-automation-id="btnLogout"
							style={{ color: "white" }}
							iconProps={{ iconName: "PowerButton" }}
						/>
						<span style={span_style}>Logout</span>
					</a>
				</Menu>
			</div>
		);
	}
}

export default Logo;
