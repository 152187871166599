import * as React from 'react';
import { Dropdown, DropdownMenuItemType } from 'office-ui-fabric-react/lib/Dropdown';
import { PrimaryButton, DefaultButton} from 'office-ui-fabric-react/lib/Button';
import { throwError, entityResultsScreen, summaryScreen, toggleLoading, clearError} from '../actions/navigationWorker';
import { GetSetting } from '../actions/settingsWorker';
import { GetItemText, GetDependOptions } from '../actions/helper';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

let doc_types = [{ key: 'Header', text: 'Document Type', itemType: DropdownMenuItemType.Header }];
let doc_subtypes = [{ key: 'Header', text: 'Document Subtype', itemType: DropdownMenuItemType.Header }];
let permissions = [{ key: 'Header', text: 'Permission', itemType: DropdownMenuItemType.Header }];
const group_name = 'entity_note';

export interface AppProps {
   hide: boolean;
}

export interface AppState {
    doc_subtypes: any,
    doc_categories: any,
    subject: string,
    selectedType: string,
    selectedSubType: string,
    selectedPermission: string,
    selectedAccess: Array<string>,
    selectedCategory: Array<string>
    invalid_subject: string
}

class NoteProperties extends React.Component<AppProps, AppState> {
    constructor(props) {
        super(props);
        let doc_categories = JSON.parse(localStorage.getItem('doc_category_results')) || [];

        this.handleSubjectChange = this.handleSubjectChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleSubTypeChange = this.handleSubTypeChange.bind(this);
        this.handlePermissionChange = this.handlePermissionChange.bind(this);
        this.handleAccessChange = this.handleAccessChange.bind(this);
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.get_docnote_type();
        this.get_docnote_sub_type();
        this.get_permissions();
        const select_perm = GetSetting('Permission_default', null);
        this.state = {
            doc_subtypes: doc_subtypes,
            doc_categories: doc_categories,
            subject: (Office.context.mailbox ? Office.context.mailbox.item.subject.replace(/[*|?:"<>\\\/]/gi, ' ') : ''),
            selectedType:  GetSetting('entity_note_type_default', null),
            selectedSubType: GetSetting('entity_note_subtype_depend_default', null),
            selectedPermission: GetSetting('Permission_default', null) === '' ? '4' : select_perm,
            selectedAccess: [],
            selectedCategory: [],
            invalid_subject: 'Invalid Subject please re-enter'
        };
        toggleLoading(false);
    }

    handleSubjectChange = (_event, item) => {
        sessionStorage.setItem('subject', item);
        this.setState({subject: item});
    }

    handleTypeChange = (_event, item) => {
        sessionStorage.setItem('doc_type', JSON.stringify({ id: item.key, name: item.text }));

        doc_subtypes = [];
        doc_subtypes.push({ key: 'Header', text: 'Document Subtype', itemType: DropdownMenuItemType.Header });

        const data = GetDependOptions(group_name, item.key);
        if (data && data.length > 0) {
            for (let i = 0, l = data.length; i < l; i++) {
                doc_subtypes.push({ key: data[i].key, text: data[i].text, itemType: DropdownMenuItemType.Normal});
            }
        }

        sessionStorage.setItem('doc_subtype', JSON.stringify({}));
        this.setState({doc_subtypes: doc_subtypes, selectedType: item.key});
    }

    handleSubTypeChange = (_event, item) => {
        sessionStorage.setItem('doc_subtype', JSON.stringify({id: item.key, name: item.text}));
        this.setState({selectedSubType: item.key});
    }

    handlePermissionChange = (_event, item) => {
        sessionStorage.setItem('doc_permission', JSON.stringify({id: item.key, name: item.text}));
        this.setState({selectedPermission: item.key});
    }

    handleAccessChange = (_event, item) => {
        let doc_access = JSON.parse(sessionStorage.getItem('doc_access')) || [];
        let selected_access = this.state.selectedAccess ? this.copyArray(this.state.selectedAccess) : [];
        if (item.selected) {
            doc_access.push({id: item.key, name: item.text});
            selected_access.push(item.key);
        } else {
            doc_access.pop({id: item.key, name: item.text});
            const currIndex = selected_access.indexOf(item.key);
            if (currIndex > -1) {
                selected_access.splice(currIndex, 1);
            }
        }
        sessionStorage.setItem('doc_access', JSON.stringify(doc_access));
        this.setState({selectedAccess: selected_access});
    }

    handleCategoryChange = (_event, item) => {
        let doc_category = JSON.parse(sessionStorage.getItem('doc_category')) || [];
        let selected_category = this.state.selectedCategory ? this.copyArray(this.state.selectedCategory) : [];
        if (item.selected) {
            doc_category.push({id: item.key, name: item.text});
            selected_category.push(item.key);
        } else {
            doc_category.pop({id: item.key, name: item.text});
            const currIndex = selected_category.indexOf(item.key);
            if (currIndex > -1) {
                selected_category.splice(currIndex, 1);
            }
        }
        sessionStorage.setItem('doc_category', JSON.stringify(doc_category));
        this.setState({selectedCategory: selected_category});
    }

    public copyArray = (array: any[]): any[] => {
        const newArray: any[] = [];
        for (let i = 0; i < array.length; i++) {
            newArray[i] = array[i];
        }
        return newArray;
    }

    back_to_results = () => {
        let results = JSON.parse(sessionStorage.getItem('search_results')) || [];
        entityResultsScreen(true, results);
    }

    summary = () => {
        toggleLoading(false);
        summaryScreen();
    }

    disableNext = (): boolean => {
        let count = 0;
        if (this.state.selectedType) {
            if (this.state.selectedType.toString().length > 0) {
                count = count + 1;
            }
        }
        if (this.state.selectedPermission) {
            if (this.state.selectedPermission.toString().length > 0) {
                count = count + 1;
            }
        }
        if (this.ValidateSubject()) {
            count = count + 1;
        }
        return count !== 3;
    }

    get_docnote_type = () => {
        let data: any;
        doc_types = [{ key: 'Header', text: 'Document Type', itemType: DropdownMenuItemType.Header}];

        data = JSON.parse(localStorage.getItem(group_name + '_type_results')) || [];
        if (data) {
            for (let i = 0; i < data.length; i++) {
             doc_types.push({ key: data[i][0], text: data[i][1], itemType: DropdownMenuItemType.Normal });
            }
        }
    }

    get_docnote_sub_type = () => {
        let data: any;
        doc_subtypes = [{ key: 'Header', text: 'Document Sub Type', itemType: DropdownMenuItemType.Header}];

        data = JSON.parse(localStorage.getItem(group_name + '_subtype_results')) || [];
        if (data) {
            for (let i = 0; i < data.length; i++) {
             doc_subtypes.push({ key: data[i][0], text: data[i][1], itemType: DropdownMenuItemType.Normal });
            }
        }
    }

    get_permissions = () => {
        permissions = [{ key: 'Header', text: 'Permission', itemType: DropdownMenuItemType.Header}];
        const data = JSON.parse(localStorage.getItem('PermissionList')) || [];
        data.forEach(x => {
            permissions.push({ key: x[0], text: x[1], itemType: DropdownMenuItemType.Normal});
        });
    }

    private ValidateSubject = (): boolean => {
        if (!this.state.subject) {
            throwError(this.state.invalid_subject);
            return false;
        }
        clearError();
        return true;
    }

    componentDidMount() {
        if (this.state.selectedSubType && this.state.selectedSubType !== 'undefined') {
            this.get_docnote_sub_type();
            this.setState({doc_subtypes: doc_subtypes});
            const text = GetItemText(group_name + '_subtype_results', this.state.selectedSubType);
            sessionStorage.setItem('doc_subtype', JSON.stringify({id: this.state.selectedSubType, name: text}));
        }
        if (this.state.selectedType) {
            const text = GetItemText(group_name + '_type_results', this.state.selectedType);
            sessionStorage.setItem('doc_type', JSON.stringify({ id: this.state.selectedType, name: text }));
        }
        if (this.state.selectedPermission) {
            let text = GetItemText('PermissionList', this.state.selectedPermission);
            let permission_id = this.state.selectedPermission;
            if (text === '') {
                sessionStorage.removeItem('Permission_default');
            }
            sessionStorage.setItem('doc_permission', JSON.stringify({id: permission_id, name: text}));
        }
        if (this.state.subject) {
            sessionStorage.setItem('subject', this.state.subject);
        }
    }

    public ArrayToObject = (array: any[]): any => {
        let object = {};
        array.forEach(element => {
            object[element[0]] = element[1];
        });
        return object;
    }

    public render() {
        let nxt_button_text = 'Next';
        if (localStorage.getItem('skip_summary') === 'true') {
            nxt_button_text = 'File';
        }

        return (
            <div>
                 <TextField
                    label='Note Subject'
                    placeholder='Subject'
                    onChange={this.handleSubjectChange}
                    value={this.state.subject}/>
                <br />
                <Dropdown
                    label='* Note Type'
                    placeholder='Type'
                    onChange={this.handleTypeChange}
                    options={doc_types}
                    defaultSelectedKey={this.state.selectedType}/>
                <br />
                <Dropdown
                   label='Note Sub Type'
                    placeholder='Subtype'
                    onChange={this.handleSubTypeChange}
                    options={this.state.doc_subtypes}
                    disabled={this.state.doc_subtypes.length < 2}
                    defaultSelectedKey={this.state.selectedSubType}/>
                <br />
                <Dropdown
                    label='* Note Permission'
                    placeholder='Permission'
                    onChange={this.handlePermissionChange}
                    options={permissions}
                    defaultSelectedKey={this.state.selectedPermission}/>
                <br />
                <Dropdown
                    label='Note Access'
                    placeholder='Access'
                    onChange={this.handleAccessChange}
                    multiSelect
                    options={[
                                { key: 'Header', text: 'Access', itemType: DropdownMenuItemType.Header },
                                { key: 'is_client_access_accessible', text: 'Client Access' },
                                { key: 'is_referrer_accessible', text: 'Referrer Access' },
                                { key: 'is_profadviser_accessible', text: 'Prof.Adviser Access' },
                    ]}
                    defaultSelectedKeys={this.state.selectedAccess}/>
                <br />
                <Dropdown
                    label='Note Categories'
                    placeholder='Categories'
                    onChange={this.handleCategoryChange}
                    multiSelect
                    options={this.state.doc_categories}
                    defaultSelectedKeys={this.state.selectedCategory}/>
                <br />
                <div>
                    <div className='footerButton ms-Grid-col ms-sm6 ms-md6 ms-lg6'>
                        <DefaultButton data-automation-id='btnBack' text='Back' onClick={ this.back_to_results }/>
                    </div>
                    <div className='footerButton ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-u-textAlignRight'>
                        <PrimaryButton data-automation-id='btnNext' text={nxt_button_text} disabled={this.disableNext()} onClick={ this.summary } />
                    </div>
                </div>
            </div>
        );
    }
}

export default NoteProperties;
