import * as React from 'react';
import { Dropdown, DropdownMenuItemType } from 'office-ui-fabric-react/lib/Dropdown';
import { PrimaryButton, DefaultButton} from 'office-ui-fabric-react/lib/Button';
import { throwError, entityResultsScreen, summaryScreen, toggleLoading, clearError } from '../actions/navigationWorker';
import { get_choice_field_dependence } from '../actions/entityWorker';
import { GetSetting } from '../actions/settingsWorker';
import { GetItemText } from '../actions/helper';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

let task_types = [{ key: 'Header', text: 'Task Type', itemType: DropdownMenuItemType.Header }];
let task_subtypes = [{ key: 'Header', text: 'Task Subtype', itemType: DropdownMenuItemType.Header }];
let task_status = [{ key: 'Header', text: 'Task Status', itemType: DropdownMenuItemType.Header }];
const group_name = 'task';

export interface AppProps {
   hide: boolean;
   data: any;
}

export interface AppState {
    task_subtypes: any,
    subject: string,
    selectedType: string,
    selectedSubType: string,
    selectedStatus: string
    invalid_subject: string
}

class NewTaskProperties extends React.Component<AppProps, AppState> {
    constructor(props) {
        super(props);

        this.handleSubjectChange = this.handleSubjectChange.bind(this);
        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleSubTypeChange = this.handleSubTypeChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.get_task_type();
        this.get_task_sub_type();
        this.get_task_status();

        this.state = {
            task_subtypes: task_subtypes,
            subject: (Office.context.mailbox ? Office.context.mailbox.item.subject.replace(/[*|?:"<>\\\/]/gi, ' ') : ''),
            selectedType: GetSetting('task_type_default', null),
            selectedSubType: GetSetting('task_subtype_depend_default', null),
            selectedStatus: GetSetting('task_status_default', null),
            invalid_subject: 'Invalid Subject please re-enter'
        };
    }

    handleSubjectChange = (_event, item) => {
        sessionStorage.setItem('subject', item);
        this.setState({subject: item});
    }

    handleTypeChange = (_event, item) => {
        let data: any;
        sessionStorage.setItem('task_type', JSON.stringify({ id: item.key, name: item.text }));
        get_choice_field_dependence(group_name, 'subtype', '');
        task_subtypes = [{ key: 'Header', text: 'Task Subtype', itemType: DropdownMenuItemType.Header }];

        data = JSON.parse(localStorage.getItem(group_name + '_subtype_results')) || [];

        const type_depends = JSON.parse(localStorage.getItem(group_name + '_subtype_depend_results')) || '';
        if (type_depends?.choice_category_dependence) {
            const cat_types = type_depends.choice_category_dependence;

            if ((cat_types !== null) && (cat_types[item.key] !== null && cat_types[item.key] !== undefined)) {
                let sub_types = cat_types[item.key];

                for (let i = 0; i < sub_types.length; i++) {
                    let fld_sub_type = sub_types[i];
                    let objType = this.ArrayToObject(data);
                    task_subtypes.push({ key: fld_sub_type, text: objType[fld_sub_type], itemType: DropdownMenuItemType.Normal });
                }
            }
        } else {
            this.get_task_sub_type();
        }

        this.setState({task_subtypes: task_subtypes, selectedType: item.key});
    }

    handleSubTypeChange = (_event, item) => {
        sessionStorage.setItem('task_subtype', JSON.stringify({id: item.key, name: item.text}));
        this.setState({selectedSubType: item.key});
    }

    handleStatusChange = (_event, item) => {
        sessionStorage.setItem('task_status', JSON.stringify({id: item.key, name: item.text}));
        this.setState({selectedStatus: item.key});
    }

    back_to_results = () => {
        let results = JSON.parse(sessionStorage.getItem('search_results')) || [];
        entityResultsScreen(true, results);
    }

    summary = () => {
        toggleLoading(false);
        summaryScreen();
    }

    disableNext = (): boolean => {
        let count = 0;
        if (this.state.selectedType !== null) {// 0 is allowed
            if (this.state.selectedType.toString().length > 0) {
                count = count + 1;
            }
        }
        if (this.state.selectedStatus !== null) {// 0 is allowed
            if (this.state.selectedStatus.toString().length > 0) {
                count = count + 1;
            }
        }
        if (this.ValidateSubject()) {
            count = count + 1;
        }
        return count !== 3;
    }

    get_task_type = () => {
        let data: any;
        task_types = [{ key: 'Header', text: 'Task Type', itemType: DropdownMenuItemType.Header }];

        data = JSON.parse(localStorage.getItem(group_name + '_taskkind_results')) || [];
        for (let i = 0; i < data.length; i++) {
            task_types.push({ key: data[i][0], text: data[i][1], itemType: DropdownMenuItemType.Normal });
        }
    }

    get_task_sub_type = () => {
        let data: any;
        task_subtypes = [{ key: 'Header', text: 'Task Sub Type', itemType: DropdownMenuItemType.Header }];

        data = JSON.parse(localStorage.getItem(group_name + '_subtype_results')) || [];
        for (let i = 0; i < data.length; i++) {
            task_subtypes.push({ key: data[i][0], text: data[i][1], itemType: DropdownMenuItemType.Normal });
        }
    }

    get_task_status = () => {
        let data: any;
        task_status = [{ key: 'Header', text: 'Task Status', itemType: DropdownMenuItemType.Header }];

        data = JSON.parse(localStorage.getItem(group_name + '_status_results')) || [];
        for (let i = 0; i < data.length; i++) {
            task_status.push({ key: data[i][0], text: data[i][1], itemType: DropdownMenuItemType.Normal });
        }
    }

    public ArrayToObject = (array: any[]): any => {
        let object = {};
        array.forEach(element => {
            object[element[0]] = element[1];
        });
        return object;
    }

    private ValidateSubject = (): boolean => {
        if (!this.state.subject) {
            throwError(this.state.invalid_subject);
            return false;
        }
        clearError();
        return true;
    }

    componentDidMount() {
        if (this.state.selectedSubType) {
          this.get_task_sub_type();
          this.setState({task_subtypes: task_subtypes});
          const text = GetItemText(group_name + '_subtype_results', this.state.selectedSubType);
          sessionStorage.setItem('task_subtype', JSON.stringify({id: this.state.selectedSubType, name: text}));
        }
        if (this.state.selectedType) {
            const text = GetItemText(group_name + '_taskkind_results', this.state.selectedType);
            sessionStorage.setItem('task_type', JSON.stringify({ id: this.state.selectedType, name: text }));
        }
        if (this.state.selectedStatus) {
            const text = GetItemText('task_status_results', this.state.selectedStatus);
            sessionStorage.setItem('task_status', JSON.stringify({id: this.state.selectedStatus, name: text}));
        }
        if (this.state.subject) {
            sessionStorage.setItem('subject', this.state.subject);
        }
    }

    public render() {

        if (this.props.hide) {
            return(null);
        }
        let nxt_button_text = 'Next';
        if (localStorage.getItem('skip_summary') === 'true') {
            nxt_button_text = 'File';
        }
        return (
            <div>
                <TextField
                    label='Task Subject'
                    placeholder='Subject'
                    onChange={this.handleSubjectChange}
                    value={this.state.subject}/>
                <br />
                <Dropdown
                    label='* Task Type'
                    placeholder='Type'
                    onChange={this.handleTypeChange}
                    options={task_types}
                    defaultSelectedKey={this.state.selectedType ? Number(this.state.selectedType) : null}/>
                <br />
                <Dropdown
                    label='Task Sub Type'
                    placeholder='Subtype'
                    id='taskSubType'
                    onChange={this.handleSubTypeChange}
                    options={this.state.task_subtypes}
                    disabled={this.state.task_subtypes.length < 2}
                    defaultSelectedKey={this.state.selectedType ? Number(this.state.selectedSubType) : null}/>
                <br />
                <Dropdown
                    label='* Task Status'
                    placeholder='Status'
                    onChange={this.handleStatusChange}
                    options={task_status}
                    defaultSelectedKey={Number(this.state.selectedStatus)}/>
                <br />

                <div>
                    <div className='footerButton ms-Grid-col ms-sm6 ms-md6 ms-lg6'>
                        <DefaultButton data-automation-id='btnBack' text='Back' onClick={ this.back_to_results }/>
                    </div>
                    <div className='footerButton ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-u-textAlignRight'>
                        <PrimaryButton data-automation-id='btnNext' text={nxt_button_text} disabled={this.disableNext()} onClick={ this.summary } />
                    </div>
                </div>
            </div>
        );
    }
}

export default NewTaskProperties;
