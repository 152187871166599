import axios from "axios";
import { throwError } from "./navigationWorker";
import { getXplanURL, APICalls, getPayload, handleResponseData } from "./helper";
import { DropdownMenuItemType } from "office-ui-fabric-react/lib/Dropdown";
import { LogError } from "../actions/errorWorker";

const ROOT_ID = "0000000000000000";
const identity = (x) => x;

export interface Item {
	catid: string;
	parentid: string | "0000000000000000";
	name: string;
	[key: string]: any;
}

export interface TreeItem {
	data: Item | null;
	label: string;
	value: string;
	children: TreeItem[];
}

export interface Config {
	catid: string;
	parentid: string;
}

export function arrayToTree(items: Item[]) {
	const rootItems: TreeItem[] = [];

	const lookup: { [catid: string]: TreeItem } = {};

	for (const item of items) {
		const itemId = item["catid"];
		const parentid = item["parentid"];
		const label = item["name"];
		const value = item["catid"];

		if (!Object.prototype.hasOwnProperty.call(lookup, itemId)) {
			lookup[itemId] = { data: null, label: label, value: value, children: [] };
		}

		lookup[itemId].data = item;

		const TreeItem = lookup[itemId];

		if (itemId === ROOT_ID) {
			rootItems.push(TreeItem);
		} else {
			if (!Object.prototype.hasOwnProperty.call(lookup, parentid)) {
				lookup[parentid] = { data: null, label: label, value: value, children: [] };
			}
			const newlabel = lookup[parentid].label;
			if (newlabel !== "Root") {
				TreeItem.label = `${newlabel}/${TreeItem.label}`;
			}

			lookup[parentid].children.push(TreeItem);
		}
	}

	const flatlist = flatten(
		rootItems,
		(node) => node.children, // obtain child nodes
		(node) => [node.value, node.label] // create output node
	);
	const mylist = flatlist.map((x) => {
		return { key: x[0], text: x[1], itemType: DropdownMenuItemType.Normal };
	});
	mylist[0] = { key: "Header", text: "Categories", itemType: DropdownMenuItemType.Header };
	return mylist;
}

function flatten(tree, getChildNodes, convertNode) {
	if (convertNode === void 0) {
		convertNode = identity;
	}
	const stack = tree && tree.length ? [{ pointer: tree, offset: 0 }] : [];
	const flat = [];
	let current;
	while (stack.length) {
		current = stack.pop();
		while (current.offset < current.pointer.length) {
			const node = current.pointer[current.offset];
			const children = getChildNodes(node);
			flat.push(convertNode(node, current.node, current.nodeId));
			current.offset += 1;
			if (children) {
				stack.push(current);
				current = {
					pointer: children,
					offset: 0,
					node: node,
				};
			}
		}
	}
	return flat;
}

export async function get_docnote_props() {
	if (localStorage.getItem("doc_category_results")) return;
	const payload = getPayload(getXplanURL() + APICalls.DocnoteProperties, null, "GET");

	try {
		const response = await axios.post(APICalls.AWSUrl(), payload);

		const data = handleResponseData(response, "No note properties found.");
		if (data) {
			const tree = arrayToTree(data);
			localStorage.setItem("doc_category_results", JSON.stringify(tree));
		}
	} catch (error) {
		throwError(error);
		LogError(error, { payload }, "get_docnote_props", "ERROR");
	}
}
