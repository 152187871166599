import * as React from 'react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

type Props = {
    hide: boolean;
}

function LoadOverlay({ hide }: Props) {
    if (hide) return null;

    return (
        <div id='overlay'>
            <Spinner className='middle' size={SpinnerSize.large} />
        </div>
    );
}

export default React.memo(LoadOverlay);