import * as React from "react";
import { loginUser } from "../actions/loginWorker";
import { DefaultButton } from "office-ui-fabric-react/lib/Button";
import { TextField } from "office-ui-fabric-react/lib/TextField";
import { Label } from "office-ui-fabric-react/lib/Label";
import { getParameterByName } from "../actions/loginWorker";
import {
	APICalls,
	setXplanURL,
	getXplanURL,
	appConfig,
	watchForAuthCode,
	getUserStorySessionGuid,
	validateSiteUrl,
	exampleUrl,
	cleanup,
} from "../actions/helper";
import { get_capabilities } from "../actions/entityWorker";
import { throwError, toggleLoading } from "../actions/navigationWorker";
import { LogError } from "../actions/errorWorker";
import { initializeIcons } from "office-ui-fabric-react/lib/Icons";

initializeIcons();

export interface AppState {
	username: string;
	password: string;
	xplan_url: string;
	username_error: string;
	password_error: string;
	xplan_url_error: string;
	hide_xplan_url: boolean;
	hide_sso_login: boolean;
}

function showNewPluginAlert(hide_xplan_url): boolean {
	if (hide_xplan_url) return false;
	let timestamp = 0;
	const nextTimestamp = localStorage.getItem("showNewPluginAlertTimestamp");
	if (nextTimestamp) timestamp = parseInt(nextTimestamp);

	const noIE = !navigator.userAgent.includes("Trident");

	const platform = Office.context.platform || Office.context.diagnostics.platform;
	const isDesktop = platform === Office.PlatformType.PC || platform === Office.PlatformType.Mac;

	return Date.now() > timestamp && noIE && isDesktop;
}

const day = 86400000;

class Login extends React.Component<any, any> {
	constructor(props, context) {
		super(props, context);
		this.state = {
			username: "",
			password: "",
			otp: "",
			xplan_url: this.props.xplan_url,
			username_error: "Username is required",
			password_error: "Password is required",
			xplan_url_error: "XPLAN Site URL is required",
			xplan_invalid_url: `Invalid Xplan URL. Please enter the url in this format ${exampleUrl()}`,
			hide_xplan_url: false,
			hide_sso_login: true,
			newPluginAlertVisible: false,
		};
	}

	hideAlert = () => {
		this.setState({ newPluginAlertVisible: false });
		localStorage.setItem("showNewPluginAlertTimestamp", String(Date.now() + day));
	};

	developerLogin = () => {
		this.setState({ username: localStorage.getItem("devLoginUser") || "" });
		this.setState({ password: localStorage.getItem("devLoginPassword") || "" });
	};

	componentDidMount() {
		const hideUrlFor = localStorage.getItem("hide_url_for") || "";
		const hiddenUrl = appConfig().hidden_urls[hideUrlFor];
		if (hiddenUrl) {
			this.setState({ hide_xplan_url: true });
			this.handleXplanURL(null, hiddenUrl.url);
		}
		this.developerLogin();
		this.handleShowSSOLogin(this.props.xplan_url);
		if (this.state.xplan_url) setXplanURL(this.state.xplan_url);
		if (showNewPluginAlert(this.state.hide_xplan_url)) {
			this.setState({ newPluginAlertVisible: true });
		}
	}

	handleUsername = (_event, item) => {
		this.setState({ username: item });
	};

	handlePassword = (_event, item) => {
		this.setState({ password: item });
	};

	handleOpt = (_event, item) => {
		this.setState({ otp: item });
	};

	handleXplanURL = (_, url: string) => {
		const cleanUrl = url.trim().toLowerCase();
		this.setState({ xplan_url: cleanUrl });
		this.handleShowSSOLogin(cleanUrl);
		setXplanURL(cleanUrl);
	};

	handleShowSSOLogin = (site_url: string) => {
		const sso_urls = appConfig().sso_enabled_sites;
		if (sso_urls?.includes(site_url)) this.setState({ hide_sso_login: false });
		else this.setState({ hide_sso_login: true });
	};

	private handleLoginClick = () => {
		try {
			if (!this.ValidateFields()) return;
			localStorage.setItem("is_sso_login", "false");
			localStorage.removeItem("capabilities_results");
			localStorage.removeItem("xplan_cookie");
			localStorage.removeItem("xplan_cookie_expire");
			setXplanURL(this.state.xplan_url);

			loginUser(
				getXplanURL() + APICalls.User,
				this.state.username,
				this.state.password,
				this.state.otp,
				get_capabilities,
				null
			);
		} catch (err) {
			throwError(err);
			LogError(err, {}, "handleLoginClick", "ERROR");
		}
	};

	private handleSSOLoginClick = () => {
		try {
			if (!this.ValidateXplanURL()) return;

			localStorage.setItem("is_sso_login", "true");
			localStorage.removeItem("auth_code");
			localStorage.removeItem("user_story_session_guid");
			localStorage.removeItem("capabilities_results");
			localStorage.removeItem("xplan_cookie");
			localStorage.removeItem("xplan_cookie_expire");
			localStorage.removeItem("access_token");
			localStorage.removeItem("refresh_token");
			localStorage.removeItem("auth_token_type");

			setXplanURL(this.state.xplan_url);
			watchForAuthCode();
			const xplan_url = this.state.xplan_url;
			const oauth_path = "/oauth2/auth?";
			const client_id = "client_id=" + appConfig().app_client_id + "&";
			const response_type = "response_type=code&";
			const oath_url = xplan_url
				.concat(oauth_path, client_id, response_type)
				.replace("https://", "");
			const callback_redirect_url = appConfig().redirect_url.concat(
				"?uss_guid=",
				getUserStorySessionGuid(),
				"&oath_url=" + oath_url
			);

			Office.context.diagnostics.platform.toString() === "OfficeOnline"
				? window.open(callback_redirect_url, "_blank")
				: Office.context.ui.openBrowserWindow(callback_redirect_url);
		} catch (err) {
			throwError(err);
			LogError(err, {}, "handleSSOLoginClick", "ERROR");
		}
	};

	keyPress = (e) => {
		if (e.keyCode === 13) {
			this.handleLoginClick();
		}
	};

	private ValidateFields = (): boolean => {
		if (this.state.username === "") {
			throwError(this.state.username_error);
			return false;
		}

		if (this.state.password === "") {
			throwError(this.state.password_error);
			return false;
		}

		if (this.state.xplan_url === "") {
			throwError(this.state.xplan_url_error);
			return false;
		}
		if (!validateSiteUrl(this.state.xplan_url)) {
			throwError(this.state.xplan_invalid_url);
			return false;
		}
		return true;
	};

	private ValidateXplanURL = (): boolean => {
		if (this.state.xplan_url === "") {
			throwError(this.state.xplan_url_error);
			return false;
		}

		if (!validateSiteUrl(this.state.xplan_url)) {
			throwError(this.state.xplan_invalid_url);
			return false;
		}

		return true;
	};

	render() {
		const container = document.getElementById("MainBody");
		let access_token = getParameterByName("useruniqueaccesstoken", null);
		let scriptId = getParameterByName("scriptID", null);
		let hide_all = (access_token && scriptId) || this.state.hide_xplan_url;
		if (this.props.hide) {
			if (container) {
				container.style.backgroundColor = "#ffffff";
			}
			return null;
		}

		if (container) {
			container.style.backgroundColor = "#3a1c46";
		}

		let login_error = "";
		if (this.props.logged_in === false) {
			login_error = this.props.error;
		}
		let style = {};
		if (login_error.length === 0) {
			style = { display: "none" };
		}

		let OTPStyle = { display: "none" };
		if (this.props.IsTFA) {
			OTPStyle = { display: "block" };
			toggleLoading(false);
		}

		return (
			<div className="ms-u-textAlignCenter">
				<br />
				<div>
					<img
						src="assets/main_logo.png"
						style={{ marginBottom: "80px", marginTop: "20px" }}
						onDoubleClick={cleanup}
					/>
				</div>
				<div style={{ width: "80%", margin: "0 auto" }}>
					{this.state.hide_xplan_url ? (
						""
					) : (
						<TextField
							name="xplan_url"
							defaultValue={this.state.xplan_url}
							placeholder="XPLAN Site URL"
							onChange={this.handleXplanURL}
							iconProps={{ iconName: "World" }}
						/>
					)}
					{hide_all ? (
						""
					) : (
						<div>
							<br />
							<TextField
								name="username"
								placeholder="Username"
								value={this.state.username}
								onChange={this.handleUsername}
								iconProps={{ iconName: "Contact" }}
							/>
						</div>
					)}
					{hide_all ? (
						""
					) : (
						<div>
							<br />
							<TextField
								name="password"
								placeholder="Password"
								value={this.state.password}
								onKeyDown={this.keyPress}
								onChange={this.handlePassword}
								type="password"
								iconProps={{ iconName: "Permissions" }}
							/>
						</div>
					)}
					<br />
					<div style={OTPStyle}>
						<TextField
							name="otp"
							placeholder="One Time Pin"
							value={this.state.otp}
							onKeyDown={this.keyPress}
							onChange={this.handleOpt}
							iconProps={{ iconName: "Permissions" }}
						/>
					</div>
					{hide_all ? (
						""
					) : (
						<div>
							<br />
							<br />
							<DefaultButton text="Login" onClick={this.handleLoginClick} />
						</div>
					)}
					<br />
					<br />
					{this.state.hide_sso_login ? (
						""
					) : (
						<DefaultButton text="SSO Login" onClick={this.handleSSOLoginClick} />
					)}
				</div>
				<div style={style}>
					<br />
					<br />
					<Label style={{ color: "red" }}>{login_error}</Label>
				</div>
			</div>
		);
	}
}

export default Login;
