import { PublicClientApplication } from "@azure/msal-browser";
import { getGraphAccessToken, setEmailAddress, setGraphAccessToken } from "../src/actions/helper";
import { FallBackRequiredType } from "../src/types";
import { scopes, config, msalErrors, getLocationUrl } from "./msal-config";

const msal = new PublicClientApplication(config);

function handleSSOErrors(err: any): FallBackRequiredType {
	const message = msalErrors[err.code];
	const fallbackRequired = !Boolean(message);
	return { fallbackRequired, message };
}

async function handlePlatformPopup(request) {
	if (Office.context.diagnostics.platform.toString() === "OfficeOnline")
		return msal.acquireTokenPopup(request);

	const dialogRedirectUrl = `${getLocationUrl()}/msal-auth/redirect.html`;

	setEmailAddress(Office.context.mailbox.userProfile.emailAddress);

	const accessToken = await new Promise<string>((resolve, reject) => {
		Office.context.ui.displayDialogAsync(
			dialogRedirectUrl,
			{ height: 90, width: 30 },
			function (result) {
				const dialog = result.value;
				dialog.addEventHandler(Office.EventType.DialogMessageReceived, (arg: any) => {
					const messageFromDialog = JSON.parse(arg.message);
					dialog.close();
					if (messageFromDialog.status === "success") {
						// Configure MSAL to use the signed-in account as the active account for future requests.
						const homeAccount = msal.getAccountByHomeId(messageFromDialog.accountId);
						msal.setActiveAccount(homeAccount);
						resolve(messageFromDialog.result);
					} else {
						reject(new Error(messageFromDialog.result));
					}
				});
				dialog.addEventHandler(Office.EventType.DialogEventReceived, (arg: any) => {
					const message = msalErrors[arg.error] ?? "Unknown error in dialog box.";
					reject(new Error(message));
				});
			}
		);
	});

	setGraphAccessToken(accessToken);

	return accessToken;
}

async function handleFallback() {
	const username = Office.context.mailbox.userProfile.emailAddress;
	const request = {
		account: msal.getAccountByUsername(username),
		scopes,
		loginHint: username,
	};

	return msal
		.acquireTokenSilent(request)
		.then((result) => result.accessToken)
		.catch(async () => {
			return handlePlatformPopup(request);
		});
}

function unwrapAccessToken(at: string | { accessToken: string }): string {
	return typeof at === "string" ? at : at?.accessToken;
}

export async function getAccessToken(): Promise<string> {
	try {
		let accessToken = getGraphAccessToken();
		if (accessToken) return unwrapAccessToken(accessToken);

		accessToken = await Office.auth.getAccessToken({
			allowSignInPrompt: true,
			allowConsentPrompt: true,
			forMSGraphAccess: true,
		});

		return unwrapAccessToken(accessToken);
	} catch (err) {
		const { fallbackRequired, message } = handleSSOErrors(err);

		if (fallbackRequired) {
			const at = await handleFallback();
			return unwrapAccessToken(at);
		}

		throw new Error(message);
	}
}
