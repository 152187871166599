"use strict";
import axios, { AxiosResponse } from "axios";
import {
	APICalls,
	getPayload,
	getXplanURL,
	trimChar,
	appConfig,
	handleResponseData,
	formatDate,
	isEmpty,
	getLoginUserName,
	updateCredentials,
	getLoginUserId,
	attachnmentTimeout,
	isNetworkError,
} from "./helper";

import { LogError } from "../actions/errorWorker";
import { toggleLoading, successScreen, errorScreen } from "./navigationWorker";
import { getAccessToken } from "../../msal-auth/auth";

const attachmentList = {};

export class XplanFiler {
	data = {};
	body = {};
	url = "";
	docId = null;
	target = "";
	ExistingDocId = "0";

	constructor(url: string) {
		this.data = {};
		this.body = {};
		this.url = url;
		this.target = "XPLAN";
		this.ExistingDocId = "0";
	}

	public async fileEmailToXplan(otherData: any) {
		if (!Office.context.mailbox) throw new Error("No Email Object");

		this.setRedirectUrl(
			getXplanURL(),
			otherData.entity_ids[0],
			otherData.fileToType,
			otherData.role
		);

		attachmentList["attachmentToken"] = await getAccessToken();
		attachmentList["itemid"] = this.getItemRestId();

		this.data["subject"] =
			otherData.subject === "" ? Office.context.mailbox.item.subject : otherData.subject;

		if (otherData.fileToType === "newtask") {
			this.data["taskkind"] = Number(otherData.tasktype);
			this.data["subtype"] = Number(otherData.tasksubtype);
			this.data["status"] = Number(otherData.taskstatus);
			this.data["description"] = Office.context.mailbox.item["subject"];
		} else if (otherData.fileToType === "activetask") {
			this.data["active_tasks"] =
				JSON.parse(sessionStorage.getItem("selected_active_tasks")) || [];
		}

		if (this.url === getXplanURL() + APICalls.FileEmail) {
			const url = this.url + (this.ExistingDocId === "0" ? "" : "/" + this.ExistingDocId);
			await this.sendToXplan(url, this.data);
		} else if (this.url === getXplanURL() + APICalls.FileEmailtoTask) {
			const url = this.url;
			await this.sendTaskToXplan(url, this.data);
		}
	}

	public async fileNoteToXplan(input: any, id = "0"): Promise<void> {
		const url = this.url + (id === "0" ? "" : `/${id}`);
		const method = !id || id === "0" ? "POST" : "PATCH";
		const payload = getPayload(url, null, method);
		const subject = input.subject || Office.context.mailbox.item.subject;

		this.setRedirectUrl(getXplanURL(), input.entity_ids[0], input.fileToType, input.role);

		try {
			const data: any = {
				subject,
				body: "",
				ext_type: "OutlookAddInV2",
				ext_item_id: Office.context.mailbox.item["conversationId"],
				ext_sub_item_id: Office.context.mailbox.item["itemId"],
				mimetype: "text/html",
				reference_date: formatDate(Office.context.mailbox.item.dateTimeCreated),
				metadata: this.generateMetaData(),
				...(method === "PATCH"
					? {
							ext_type: "",
							ext_item_id: "",
							ext_sub_item_id: "",
					  }
					: {}),
				...(this.ExistingDocId === "0"
					? {
							subject: input.subject,
							doctype: input.doctype,
							docsubtype: input.docsubtype,
							entity_ids: input.entity_ids.slice(),
							permission: input.permission,
							categories: input.categories,
							is_client_access_accessible: input.is_client_access_accessible,
							is_referrer_accessible: input.is_referrer_accessible,
							is_profadviser_accessible: input.is_profadviser_accessible,
					  }
					: {}),
			};

			payload.data = data;
			payload.target = "EWSXPLAN";
			payload.method = method;
			payload.accessToken = await getAccessToken();
			payload.restUrl = APICalls.MicrosoftGraph;
			payload.itemId = trimChar(this.getItemRestId(), "/");
			payload.is_task = false;
			payload.subject = subject;
			payload.user_id = getLoginUserId();
			payload.user_message_url = getXplanURL() + APICalls.UserMessage;
			payload.user_email_address = this.getCorrectToAddress();

			// file email + attachments
			const response = await axios.post(APICalls.AWSUrl(), payload);

			this.logApiResponse(response, "SendToXplan Response");

			const resData = handleResponseData(response, "Failed to file to Xplan.");

			if (resData) {
				// update access credentials
				updateCredentials(response, resData);

				if (resData.isError === true) {
					let message = response.data.message;
					if (response.data?.message?.includes(attachnmentTimeout))
						message = message.slice(43);
					LogError(resData, { payload }, "getCurrentItems", "ERROR");
					return errorScreen(false, "", "", message);
				} else {
					return successScreen(
						false,
						sessionStorage.getItem("redirectUrl"),
						"Go to Notes",
						resData.message
					);
				}
			}

			return errorScreen(
				false,
				"",
				"",
				"An unexpected error has occured. Please try again later."
			);
		} catch (error) {
			toggleLoading(false);

			let message = error.message;

			if (message === "Network Error" || isNetworkError(error)) {
				return successScreen(
					false,
					sessionStorage.getItem("redirectUrl"),
					"Go to Notes",
					attachnmentTimeout
				);
			}

			if (error.isAxiosError && error.response.status === 413) {
				message =
					"Your email content exceeds the max limit of 10MB. Please remove the unnecessary content/images and try again.";
			}

			if (error.isAxiosError)
				this.logApiResponse(error.response, "SendToXplan Error", payload);
			else LogError(error, { payload }, "sendToXplan", "ERROR");

			errorScreen(false, null, null, message);
		}
	}

	public async fileExistingNoteToXplan(docId: string, input: any) {
		return this.fileNoteToXplan(input, docId);
	}

	public escapeUnicode(str: string) {
		if (isEmpty(str)) return str;
		return str.replace(/[^\x00-\x7F]+/g, (ch) => {
			return "\\u" + ("000" + ch.charCodeAt(0).toString(16)).slice(-4);
		});
	}

	getItemRestId(): string {
		const id =
			Office.context.mailbox.diagnostics.hostName === "OutlookIOS"
				? Office.context.mailbox.item.itemId
				: Office.context.mailbox.convertToRestId(
						Office.context.mailbox.item.itemId,
						Office.MailboxEnums.RestVersion.v2_0
				  );

		return encodeURIComponent(id);
	}

	async getAccessToken(): Promise<string> {
		return new Promise((resolve, reject) => {
			Office.context.mailbox.getCallbackTokenAsync({ isRest: true }, (result) => {
				if (result.status !== Office.AsyncResultStatus.Succeeded)
					return reject(result.error);

				let token = result.value as any;
				if (typeof result.value !== "string") token = token?.accessTpoken;

				resolve(token);
			});
		});
	}

	private generateMetaData(): Record<string, any> {
		return {
			conversationid: Office.context.mailbox.item["conversationId"],
			conversationindex: Office.context.mailbox.item["itemId"],
			"filed-by": getLoginUserName(),
			"filed-by-address": this.getCorrectToAddress(),
			"email-date": formatDate(Office.context.mailbox.item.dateTimeCreated),
			"email-from": Office.context.mailbox.item["sender"].emailAddress,
			"email-from-address": Office.context.mailbox.item["sender"].emailAddress,
			"email-to": this.arrayToString(Office.context.mailbox.item["to"], "emailAddress", ";"),
			"email-to-address": this.arrayToString(
				Office.context.mailbox.item["to"],
				"emailAddress",
				";"
			),
			"email-cc": this.arrayToString(Office.context.mailbox.item["cc"], "emailAddress", ";"),
		};
	}

	private arrayToString(arrayData: Array<object>, field: string, delimit: string): string {
		return arrayData.map((item) => item[field]).join(delimit);
	}

	private getCorrectToAddress(): string {
		return Office.context.mailbox.userProfile.emailAddress;
	}

	private logApiResponse(resp: AxiosResponse<any>, fn: string, extraData = {}, message = "") {
		const payload = resp
			? { data: resp?.data, status: resp?.status, url: resp?.config?.url, ...extraData }
			: extraData;
		LogError(new Error(message || "API Response"), payload, fn);
	}

	private async sendToXplan(url: string, data: any) {
		const method = this.ExistingDocId === "0" ? "POST" : "PATCH";
		const payload = getPayload(url, null, method);

		if (method === "PATCH") {
			data["ext_type"] = "";
			data["ext_item_id"] = "";
			data["ext_sub_item_id"] = "";
		}

		payload["data"] = data;

		try {
			// file email
			const response = await axios.post(APICalls.AWSUrl(), payload);

			this.logApiResponse(response, "SendToXplan Response");

			const resData = handleResponseData(response, "Failed to file to Xplan.");

			// update access credentials
			updateCredentials(response, resData);

			// file attachments
			if (!resData["id"])
				throw new Error("Failed to file docnote attachment. Docnote id is missing.");
			await this.fetchAttachment(url, resData["id"], false, this.ExistingDocId);
		} catch (error) {
			toggleLoading(false);

			let message = error.message;

			if (error.isAxiosError && error.response.status === 413) {
				message =
					"Your email content exceeds the max limit of 10MB. Please remove the unnecessary content/images and try again.";
			}

			if (error.isAxiosError)
				this.logApiResponse(error.response, "SendToXplan Error", payload, message);
			else LogError(error, { payload }, "sendToXplan", "ERROR");

			errorScreen(false, null, null, message);
		}
	}

	async sendTaskToXplan(url: string, data: any) {
		try {
			const activeTasks = data["active_tasks"];

			if (activeTasks) {
				const token = await getAccessToken();
				await Promise.all(
					activeTasks.map((at: any) =>
						this.fetchAttachment(url, at?.id, true, "0", token)
					)
				);
			} else {
				const clients = JSON.parse(sessionStorage.getItem("selected_clients"));
				const selected_eIds = clients.map((x: any) => x?.id);
				await this.sendSingleTaskToXplan(url, data, selected_eIds, 0);
			}
		} catch (error) {
			errorScreen(false, null, null, error.message);
			LogError(error, {}, "sendTaskToXplan");
		}
	}

	async sendSingleTaskToXplan(url: string, data: any, selected_eIds: number[], index: number) {
		const payload = getPayload(url, null, "POST");

		try {
			data["client"] = selected_eIds[index];
			payload["data"] = data;

			const response = await axios.post(APICalls.AWSUrl(), payload);

			const responseData = handleResponseData(response, "Failed to file to Xplan.");

			if (responseData) {
				await this.fetchAttachment(url, responseData.id, true);
				if (index < selected_eIds.length - 1) {
					const i = index + 1;
					await this.sendSingleTaskToXplan(url, data, selected_eIds, i);
				}
			} else {
				errorScreen(
					false,
					"",
					"",
					"An unexpected error has occured. Please try again later."
				);
			}
		} catch (error) {
			toggleLoading(false);
			errorScreen(false, null, null, error.message);
			LogError(error, { payload: payload }, "sendToXplan", "ERROR");
		}
	}

	private async fetchAttachment(
		url: string,
		docid: string,
		is_task = false,
		existing_doc_id = "0",
		accessToken = ""
	) {
		const _url = existing_doc_id === "0" ? url + "/" + docid : url;
		const payload = getPayload(_url + "/attachment", null, "POST");

		try {
			let _accessToken = accessToken;
			if (!accessToken) _accessToken = await getAccessToken();

			let subject = "";
			if (Office.context.mailbox.item.subject) {
				subject = Office.context.mailbox.item.subject.replace(/[*|?:"<>\\\/]/gi, " ");
			}

			payload["target"] = "EWS";
			payload["ews_method"] = "GetItem";
			payload["accessToken"] = _accessToken;
			payload["restUrl"] = APICalls.MicrosoftGraph;
			payload["itemId"] = trimChar(this.getItemRestId(), "/");
			payload["xplan_url"] = _url + "/attachment";
			payload["is_task"] = is_task;
			payload["subject"] = subject;
			payload["user_id"] = localStorage.getItem("loginUserId");
			payload["user_message_url"] = getXplanURL() + APICalls.UserMessage;
			payload["user_email_address"] = this.getCorrectToAddress();

			const response = await axios.post(APICalls.AWSUrl(), payload);

			this.logApiResponse(response, "FetchAttachment Response");

			const data = handleResponseData(response, "Failed to file to Xplan.");

			if (data) {
				if (data.isError === true) {
					let message = response.data.message;
					if (response.data?.message?.includes(attachnmentTimeout))
						message = message.slice(43);
					LogError(data, { payload: payload }, "getCurrentItems", "ERROR");
					errorScreen(false, "", "", message);
				} else {
					const title = is_task ? "Go to Tasks" : "Go to Notes";
					successScreen(
						false,
						sessionStorage.getItem("redirectUrl"),
						title,
						data.message
					);
				}
			} else {
				errorScreen(
					false,
					"",
					"",
					"An unexpected error has occured. Please try again later."
				);
			}
		} catch (error) {
			const error_message = error.message;

			if (error.message === "Network Error") {
				const title = is_task ? "Go to Tasks" : "Go to Notes";
				return successScreen(
					false,
					sessionStorage.getItem("redirectUrl"),
					title,
					"Your .eml file may take several minutes to upload"
				);
			}

			if (error.isAxiosError)
				this.logApiResponse(error.response, "FetchAttachment Error", null, error_message);
			else LogError(error, { payload }, "getCurrentItems", "ERROR");

			errorScreen(false, null, null, error_message);
		}
	}

	public setRedirectUrl(xplanUrl: string, entityId: string, type: string, role: string): void {
		const hiddenUrl = appConfig().hidden_urls[localStorage.getItem("hide_url_for") || ""];
		let _xplanUrl = xplanUrl;

		if (hiddenUrl) {
			if (hiddenUrl.redirect) _xplanUrl = hiddenUrl.redirect;
			else return;
		}

		if (type === "note" && localStorage.getItem("custom_note_url")) {
			type = localStorage.getItem("custom_note_url");
		} else if (type === "newtask" || type === "activetask") {
			type = "tasks";

			const task_url = localStorage.getItem("custom_task_url");
			if (task_url) {
				type = task_url;
			}
		}

		role = role || sessionStorage.getItem("selected_entity_type") || "client";
		const redirectUrl = `${_xplanUrl}/factfind/view/${entityId}?role=${role}&page=${type}`;
		sessionStorage.setItem("redirectUrl", redirectUrl);
	}
}
