import * as React from "react";
import { DefaultButton } from "office-ui-fabric-react/lib/Button";
import { entityCriteriaScreen, summaryScreen } from "../actions/navigationWorker";

type Props = {
	link: string;
	linkTitle: string;
	hide: boolean;
	message: Error | string;
	options?: {
		hideTitle: boolean;
		hideRetry: boolean;
		backTitle: string;
	};
};

function ErrorMessage({ link, message: err, linkTitle, hide, options }: Props) {
	if (hide === true) return null;

	const message = typeof err === "string" ? err : err.message;

	function back_to_start() {
		entityCriteriaScreen(true);
	}

	function back_to_summary() {
		summaryScreen();
	}

	const header = options?.hideTitle ? null : (
		<div className="ms-u-textAlignCenter">
			<br />
			<br />
			<img alt="error icon" src="assets/error.png"></img>
			<p>Unfortunately an error has occurred while filing the email to Xplan.</p>
		</div>
	);

	const footer = (
		<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
			{options?.hideRetry !== true && (
				<div className="">
					<DefaultButton
						data-automation-id="btnRetry"
						text="Retry"
						onClick={back_to_summary}
					/>
				</div>
			)}
			<div className="">
				<DefaultButton
					data-automation-id="btnRestart"
					text={options?.backTitle || "Restart"}
					onClick={back_to_start}
				/>
			</div>
		</div>
	);

	if (link && message) {
		return (
			<div>
				{header}
				<div className="ms-u-textAlignCenter" style={{ color: "red" }}>
					{message}
				</div>
				<br />
				<br />
				<div className="ms-u-textAlignCenter">
					<a href={link} target="_blank" rel="noreferrer">
						{linkTitle}
					</a>
				</div>
				<br />
				{footer}
			</div>
		);
	}

	if (message) {
		return (
			<div>
				{header}
				<div className="ms-u-textAlignCenter" style={{ color: "red" }}>
					{message}
				</div>
				<br />
				{footer}
			</div>
		);
	}

	if (link) {
		return (
			<div>
				{header}
				<div className="ms-u-textAlignCenter">
					<a href={link} target="_blank" rel="noreferrer">
						{linkTitle}
					</a>
				</div>
				<br />
				{footer}
			</div>
		);
	}

	return (
		<div>
			{header}
			{footer}
		</div>
	);
}

export default ErrorMessage;
