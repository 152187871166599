import * as React from 'react';
import { PrimaryButton, DefaultButton} from 'office-ui-fabric-react/lib/Button';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import {get_capabilities} from '../actions/entityWorker';
import {HasCapability, GetUfieldData} from '../actions/loginWorker';
import {getUserFieldDefaults} from '../actions/backgroundWorker';
import {toggleLoading, renderRefreshFields} from '../actions/navigationWorker';

export interface AppProps {
}

export interface AppState {
    clearSettings : boolean,
    checked_caps: boolean,
    checked_defaults: boolean,
    checked_dropdowns: boolean
}

class RefreshFields extends React.Component<AppProps, AppState> {

    constructor(props) {
        super(props);
        this.state = {
            clearSettings : false,
            checked_caps: false,
            checked_defaults: false,
            checked_dropdowns: false
        };
    }

    ResetAll = async () => {
        toggleLoading(true);
        renderRefreshFields(true);
        if (this.state.checked_dropdowns) {
            localStorage.removeItem('xplan_ufield_expire');
            await GetUfieldData(true);
        }

        if ( this.state.checked_defaults) {
            await getUserFieldDefaults();
        }

        if (this.state.checked_caps) {
            localStorage.removeItem('capabilities_results');
            await get_capabilities(HasCapability, ['file_email_as_docnote', 'file_email_as_task']);
            return;
        }
        location.reload();
    }

    Cancel = () => {
        location.reload();
    }

    confirmClear = (_event) => {
        this.setState({clearSettings: true});
    }

    cancelConfirmClear = (_event) => {
        this.setState({clearSettings: false});
    }

    handle_caps_checkbox_change = (_ev: React.FormEvent<HTMLElement>, isChecked: boolean) => {
        this.setState({checked_caps: isChecked});
    }

    handle_default_checkbox_change = (_ev: React.FormEvent<HTMLElement>, isChecked: boolean) => {
        this.setState({checked_defaults: isChecked});
    }

    handle_dropdown_checkbox_change = (_ev: React.FormEvent<HTMLElement>, isChecked: boolean) => {
        this.setState({checked_dropdowns: isChecked});
    }

    public ConfirmSettingsClear(props) {
        const style = {marginTop: '19px'};
        return(
            <div style={style} >
                Are you sure?
                <br/>
                <br/>
                <DefaultButton data-automation-id='btnCancelReset' text='No' onClick={ props.cancelConfirmClear } />
                &nbsp;&nbsp;&nbsp;
                <PrimaryButton data-automation-id='btnReset' text='Yes' onClick={ props.ResetAll } />
            </div>
        );
    }

    public render() {
        const confirm = this.state.clearSettings;
        let confirmView = null;
        let button_style = confirm === true ? { display: 'none', marginTop: '5px' } : {marginTop: '19px'};

        if (confirm) {
            confirmView =  <this.ConfirmSettingsClear cancelConfirmClear={this.cancelConfirmClear} ResetAll={this.ResetAll} />;
        }
        return (
            <div>
                <div style={{marginTop: '19px'}}>
                    Please select the options to reload from your user preferences:
                </div>
                <div style={{marginTop: '19px'}}>
                    <Checkbox checked={this.state.checked_caps} label='Capabilities' onChange={this.handle_caps_checkbox_change} />
                    <br></br>
                    <Checkbox checked={this.state.checked_defaults} label='Field Defaults' onChange={this.handle_default_checkbox_change} />
                    <br></br>
                    <Checkbox checked={this.state.checked_dropdowns} label='Field Dropdown Options' onChange={this.handle_dropdown_checkbox_change} />
                </div>
                <div style={{marginTop: '19px'}}>
                    {confirmView}
                </div>
                <div>
                    <div className='footerButton ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-u-textAlignLeft'>
                        <DefaultButton style={button_style} data-automation-id='btnNext' text='Cancel' onClick={ this.Cancel } />
                    </div>
                    <div className='footerButton ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-u-textAlignRight'>
                        <PrimaryButton  data-automation-id='btnContinue'
                            style={button_style}
                            disabled={!this.state.checked_caps && !this.state.checked_defaults && !this.state.checked_dropdowns}
                            text='Continue' onClick={ this.confirmClear } />
                    </div>
                </div>
            </div>
        );
    }
}

export default RefreshFields;
