import axios from "axios";
import { entityResultsScreen, throwError, toggleLoading } from "./navigationWorker";
import { getXplanURL, APICalls, getPayload, handleResponseData, SetPermissionList } from "./helper";
import { LogError } from "../actions/errorWorker";

export async function client_search(searchCriteria, entityType, entityStatus) {
	const URLParam = makeSearchParams(entityType, searchCriteria, entityStatus);
	const payload = getPayload(getXplanURL() + APICalls.EntitySearch + URLParam, null, "GET");
	await axios
		.post(APICalls.AWSUrl(), payload)
		.then((response) => {
			const data = handleResponseData(
				response,
				"No data found matching the search criteria."
			);
			if (response.data.isError === true) {
				toggleLoading(false);
				return;
			}
			if (data) {
				entityResultsScreen(false, data);
				sessionStorage.setItem("search_results", JSON.stringify(data));
			} else {
				entityResultsScreen(true, []);
				sessionStorage.removeItem("search_results");
				throw new Error("No data found matching the search criteria.");
			}
		})
		.catch((error) => {
			throwError(error);
			LogError(error, { payload: payload }, "client_search", "ERROR");
		});
}

export async function get_choice_field_dependence(
	group: string,
	field_name: string,
	callback: any,
	callback_data: any = null,
	loadUFields: boolean = false
) {
	const key = `${group}_${field_name}_depend_results`;
	if (localStorage.getItem(key) && !loadUFields) return;
	const payload = getPayload(
		`${getXplanURL()}${APICalls.Ufieldsearch}${group}/${field_name}/choice_category_dependence`
	);
	try {
		const response = await axios.post(APICalls.AWSUrl(), payload);
		const data = handleResponseData(response, "No ufield values found.");
		if (data) {
			localStorage.setItem(key, JSON.stringify(data.isError ? {} : data));
			if (callback) callback(callback_data, false);
		}
	} catch (error) {
		throwError(error);
	}
}

export async function get_ufield_values(group: string, field: string, loadUfields: boolean) {
	const key = `${group}_${field}_results`;

	if (localStorage.getItem(key) && !loadUfields) return;

	const payload = getPayload(getXplanURL() + `${APICalls.Ufieldsearch}${group}/${field}/choice`);

	try {
		const response = await axios.post(APICalls.AWSUrl(), payload);
		const data = handleResponseData(response, "No ufield values found.");
		if (data) localStorage.setItem(key, JSON.stringify(data));
	} catch (error) {
		throwError(error);
		LogError(error, { payload: payload }, "get_ufield_values", "ERROR");
	}
}

export async function get_capabilities(successCallback, caps) {
	if (localStorage.getItem("capabilities_results")) {
		if (successCallback) successCallback(caps);
		SetPermissionList();
	} else {
		const payload = getPayload(getXplanURL() + APICalls.Capabilities, null, "GET");
		try {
			await axios.post(APICalls.AWSUrl(), payload).then((response) => {
				try {
					const data = handleResponseData(response, "No capabilities values found.");
					if (data) {
						if (successCallback) {
							localStorage.setItem("capabilities_results", JSON.stringify(data));
							successCallback(caps);
						}
						SetPermissionList();
					}
				} catch (error) {
					throwError(error.message);
					LogError(error, { payload: payload }, "get_capabilities");
				}
			});
		} catch (error) {
			throwError(error);
			LogError(error, { payload: payload }, "get_capabilities", "ERROR");
		}
	}
}

export function has_capability(capability: string) {
	const capabilities: string[] = JSON.parse(localStorage.getItem("capabilities_results")) || [];
	return capabilities?.includes(capability) ?? false;
}

export function makeSearchParams(entityType, fields, statuses = []) {
	let criteria = "";
	if (!fields) {
		return entityType;
	}
	switch (entityType) {
		case "client-v2":
			criteria = "?quicksearch=" + encodeURIComponent(fields["entityName"] || "");
			if (fields["pcode"]) {
				criteria = criteria.concat(" pcode:" + encodeURIComponent(fields["pcode"]));
			}

			if (fields["dob"]) {
				criteria = criteria.concat(" dob:" + encodeURIComponent(fields["dob"]));
			}

			["dob", "preferred_postcode", "client_adviser_name"].forEach((x, index) => {
				criteria = criteria.concat(`&fields.${index}=${encodeURIComponent(x)}`);
			});

			statuses.forEach((x, index) => {
				criteria = criteria.concat(
					`&include_client_status.${index}=${encodeURIComponent(x)}`
				);
			});
			break;
		case "user":
			criteria = `?quicksearch=${encodeURIComponent(fields["entityName"] || "")}`;
			break;
		case "referrer":
			criteria = `?quicksearch=${encodeURIComponent(fields["entityName"] || "")}`;
			break;
		case "supplier":
			criteria = `?quicksearch=${encodeURIComponent(fields["entityName"] || "")}`;
			break;
		case "profadviser":
			criteria = `?quicksearch=${encodeURIComponent(fields["entityName"] || "")}`;
			break;
	}

	return entityType + criteria;
}
