import * as React from "react";
import * as ReactDOM from "react-dom";

import { checkValidCookie } from "./loginWorker";
import { LogError } from "../actions/errorWorker";
import Logo from "../components/Logo";
import Login from "../components/login";
import EntitySelection from "../components/EntitySelection";
import SearchResults from "../components/SearchResults";
import FileEmailTo from "../components/FileEmailTo";
import NoteProperties from "../components/NoteProperties";
import ActiveTaskProperties from "../components/ActiveTaskProperties";
import NewTaskProperties from "../components/NewTaskProperties";
import SuccessMessage from "../components/SuccessMessage";
import ErrorMessage from "../components/ErrorMessage";
import ErrorMessageOnPage from "../components/ErrorMessageOnPage";
import Summary from "../components/Summary";
import LoadOverlay from "../components/LoadOverlay";
import ExistingDocNote from "../components/ExistingDocNote";
import RefreshFields from "../components/RefreshFields";
import Splash from "../components/Splash";
import { get_activetask_props } from "../actions/activetasksWorker";
import { FindDocNotes } from "../actions/FilerWorker";
import {
	formatURL,
	getXplanURL,
	APICalls,
	getXplanCookie,
	IsOverlayShown,
	getQueryParams,
	setConfig,
	setAxiosDefaults,
	clearLogin,
	validateSiteUrl,
} from "./helper";
import { getCustomURLS } from "../actions/backgroundWorker";
import { PrimaryButton } from "office-ui-fabric-react/lib/Button";
import { AppConfig } from "../types";
import { errorMessage } from "../../msal-auth/msal-config";
import { getAccessToken } from "../../msal-auth/auth";

export function failed_login(error: string, logout = false) {
	clearLogin();
	if (error) {
		LogError(error, null, "failed_login", "ERROR");
	}

	if (logout) {
		location.reload();
		const tfaCookie = localStorage.getItem("tfa_cookie");
		localStorage.clear();
		if (tfaCookie) localStorage.setItem("tfa_cookie", tfaCookie);
	} else {
		renderLogo(false, true);
		renderExistingDocNote(true, null);
		renderEntityCriteria(true);
		renderSearchResults(true, "");
		renderFileToType(true);
		renderNoteProperties(true);
		renderNewTaskProperties(true);
		renderActiveTaskProperties(true);
		renderSummary(true);
		renderErrorMessage(true, "", "", "");
		renderErrorMessageOnPage(true, "");
		renderLogin(false, false, error);
		renderSuccessMessage(true, "", "", "");
		toggleLoading(false);
	}

	localStorage.removeItem("entity_note_type_results");
	localStorage.removeItem("entity_note_subtype_results");
	localStorage.removeItem("task_taskkind_results");
	localStorage.removeItem("task_subtype_results");
	localStorage.removeItem("task_status_results");
	localStorage.removeItem("xplan_ufield_expire");
	localStorage.removeItem("entity_note_subtype_depend_results");
	localStorage.removeItem("task_subtype_depend_results");
	sessionStorage.clear();
}

export function renderLogin(hide, logged_in, error = "", IsTFA = false) {
	const url = getXplanURL() || "";
	if (hide !== true) {
		clearLogin();
	}
	if (hide === null) {
		ReactDOM.render(<Login xplan_url={url} />, document.getElementById("Login"));
	} else {
		ReactDOM.render(
			<Login xplan_url={url} hide={hide} logged_in={logged_in} error={error} IsTFA={IsTFA} />,
			document.getElementById("Login")
		);
	}
}

function renderEntityCriteria(hide) {
	if (hide) {
		ReactDOM.unmountComponentAtNode(document.getElementById("SearchCriteria"));
	} else {
		ReactDOM.render(<EntitySelection hide={hide} />, document.getElementById("SearchCriteria"));
	}
}

function renderSearchResults(hide, data) {
	if (hide || data.length === 0) {
		ReactDOM.unmountComponentAtNode(document.getElementById("SearchResults"));
	} else {
		ReactDOM.render(
			<SearchResults hide={hide} data={data} />,
			document.getElementById("SearchResults")
		);
	}
}

function renderFileToType(hide) {
	if (hide) {
		ReactDOM.unmountComponentAtNode(document.getElementById("FileEmailTo"));
	} else {
		ReactDOM.render(<FileEmailTo hide={hide} />, document.getElementById("FileEmailTo"));
	}
}

function renderActiveTaskProperties(hide) {
	get_activetask_props(hide);
}

function renderNewTaskProperties(hide) {
	if (hide) {
		ReactDOM.unmountComponentAtNode(document.getElementById("NewTaskProperties"));
	} else {
		ReactDOM.render(
			<NewTaskProperties hide={hide} data={[]} />,
			document.getElementById("NewTaskProperties")
		);
	}
}

export function renderNoteProperties(hide) {
	if (hide) {
		ReactDOM.unmountComponentAtNode(document.getElementById("NoteProperties"));
	} else {
		ReactDOM.render(<NoteProperties hide={hide} />, document.getElementById("NoteProperties"));
	}
}

export function renderActiveTaskScreen(data, hide) {
	if (hide) {
		ReactDOM.unmountComponentAtNode(document.getElementById("ActiveTaskProperties"));
	} else {
		ReactDOM.render(
			<ActiveTaskProperties hide={hide} data={data} />,
			document.getElementById("ActiveTaskProperties")
		);
	}
}

function renderSuccessMessage(hide, link, linkTitle, message) {
	if (hide) {
		ReactDOM.unmountComponentAtNode(document.getElementById("SuccessMessage"));
	} else {
		ReactDOM.render(
			<SuccessMessage hide={hide} link={link} linkTitle={linkTitle} message={message} />,
			document.getElementById("SuccessMessage")
		);
	}
}

function renderErrorMessage(
	hide: boolean,
	link: string,
	linkTitle: string,
	message: string,
	options?: {
		hideTitle: boolean;
		hideRetry: boolean;
		backTitle: string;
	}
) {
	if (hide) {
		ReactDOM.unmountComponentAtNode(document.getElementById("ErrorMessage"));
	} else {
		ReactDOM.render(
			<ErrorMessage
				hide={hide}
				link={link}
				linkTitle={linkTitle}
				message={message}
				options={options}
			/>,
			document.getElementById("ErrorMessage")
		);
	}
}

function renderErrorMessageOnPage(hide, message) {
	if (hide) {
		ReactDOM.unmountComponentAtNode(document.getElementById("ErrorMessageOnPage"));
	} else {
		ReactDOM.render(
			<ErrorMessageOnPage hide={hide} error={message} />,
			document.getElementById("ErrorMessageOnPage")
		);
	}
}

function renderSummary(hide) {
	if (hide) {
		ReactDOM.unmountComponentAtNode(document.getElementById("Summary"));
	} else {
		ReactDOM.render(<Summary hide={hide} />, document.getElementById("Summary"));
	}
}

export function renderLogo(hide: boolean, login = false) {
	if (hide) {
		ReactDOM.unmountComponentAtNode(document.getElementById("Logo"));
	} else {
		ReactDOM.render(<Logo hide={hide} login={login} />, document.getElementById("Logo"));
	}
}

export function renderSplash(hide: boolean) {
	if (hide) {
		ReactDOM.unmountComponentAtNode(document.getElementById("Logo"));
	} else {
		ReactDOM.render(<Splash />, document.getElementById("Logo"));
	}
}

function renderLoading(hide) {
	if (hide) {
		ReactDOM.unmountComponentAtNode(document.getElementById("loadPlaceholder"));
	} else {
		ReactDOM.render(<LoadOverlay hide={hide} />, document.getElementById("loadPlaceholder"));
	}
}

function renderExistingDocNote(hide, data) {
	if (hide) {
		ReactDOM.unmountComponentAtNode(document.getElementById("ExistingDocNote"));
	} else {
		ReactDOM.render(
			<ExistingDocNote hide={hide} data={data} />,
			document.getElementById("ExistingDocNote")
		);
	}
}

export function renderRefreshFields(hide) {
	if (hide) {
		ReactDOM.unmountComponentAtNode(document.getElementById("RefreshFields"));
	} else {
		ReactDOM.render(<RefreshFields />, document.getElementById("RefreshFields"));
	}
}

//call before long task and then when you are rendering screen
export function toggleLoading(show: boolean) {
	if (show === true) renderLoading(false);
	else if (show === false) renderLoading(true);
	else if (IsOverlayShown()) renderLoading(false);
	else renderLoading(true);
}

export async function start(config: AppConfig) {
	/*  Check the first param of the url to determine if it should be hidden
        Sanlam for example
        update the app_config.json dictionary for any new sites to be hidden
        You need to create a new manifest.xml for each site to hide eg. Sanlam_manifest.xml
    */
	setConfig(config);
	setAxiosDefaults();
	let params_obj = getQueryParams();
	if (params_obj.hide_url_for) {
		localStorage.setItem("hide_url_for", params_obj.hide_url_for.toString());
	} else {
		localStorage.removeItem("hide_url_for");
	}

	const url = getXplanURL() + APICalls.User;
	const xplan_cookie = getXplanCookie() || "";
	if (validateSiteUrl(url, false) && xplan_cookie !== "") {
		await check_valid_cookie(formatURL(url));
	} else {
		localStorage.removeItem("xplan_cookie");
		localStorage.removeItem("xplan_cookie_expire");
		renderLogo(false, true);
		renderLogin(null, true);
	}
}

export async function check_valid_cookie(url) {
	await checkValidCookie(url);
}

export function handleEmailHistory() {
	/* get email history from xplan filer*/
	FindDocNotes();
}

export function existingDocNoteScreen(data) {
	/*render existing docnotes*/
	renderLogo(false);
	renderLogin(true, true);
	renderEntityCriteria(true);
	renderExistingDocNote(false, data);
}

export async function ExistingCheck() {
	let message = errorMessage;
	const accessToken = await getAccessToken().catch((error) => {
		message = error.message;
		toggleLoading(false);
		LogError(error, {}, "ExistingCheck", "ERROR");
	});

	if (!accessToken) {
		clearLogin();
		toggleLoading(false);
		renderErrorMessageOnPage(false, message);
		return;
	}

	toggleLoading(true);
	renderLogo(false);
	renderLogin(true, true);
	renderEntityCriteria(true);
	renderErrorMessageOnPage(true, "");
	renderErrorMessage(true, "", "", "");
	renderSuccessMessage(true, "", "", "");
	setTimeout(getCustomURLS, 2000);
	renderSearchResults(true, []);
	handleEmailHistory();
	toggleLoading(false);
}

export function entityCriteriaScreen(backwards) {
	renderLogo(false);
	renderLogin(true, true);
	if (backwards) {
		renderSearchResults(true, []);
	}
	renderEntityCriteria(false);
	renderErrorMessageOnPage(true, "");
	renderErrorMessage(true, "", "", "");
	renderSuccessMessage(true, "", "", "");
	renderExistingDocNote(true, null);
	renderFileToType(true);
}

export function entityResultsScreen(backwards, data) {
	renderLogo(false);
	if (backwards) {
		renderFileToType(true);
		renderEntityCriteria(false);
		renderNoteProperties(true);
		renderActiveTaskProperties(true);
		renderNewTaskProperties(true);
	}
	renderSearchResults(true, []);
	renderSearchResults(false, data);
	renderErrorMessageOnPage(true, "");
	toggleLoading(false);
}

export function fileToTypeScreen(backwards) {
	renderLogo(false);
	renderSearchResults(true, []);
	renderEntityCriteria(true);
	if (backwards) {
		renderNoteProperties(true);
		renderActiveTaskProperties(true);
		renderNewTaskProperties(true);
		renderSummary(true);
	} else {
		const file_to_type = JSON.parse(sessionStorage.getItem("file_to_type")) || {};
		if (file_to_type.id === "note") {
			renderNoteProperties(false);
			toggleLoading(false);
		} else if (file_to_type.id === "activetask") {
			renderActiveTaskProperties(false);
		} else if (file_to_type.id === "newtask") {
			renderNewTaskProperties(false);
			toggleLoading(false);
		} else {
			toggleLoading(false);
		}
	}
	renderFileToType(false);
	renderErrorMessageOnPage(true, "");
	renderSuccessMessage(true, "", "", "");
}

export function notesScreen(backwards) {
	renderNoteProperties(false);
	renderActiveTaskProperties(true);
	renderNewTaskProperties(true);
	if (backwards) {
		renderFileToType(false);
		renderSummary(true);
	}
	renderErrorMessageOnPage(true, "");
	toggleLoading(false);
}

export function activeTaskScreen(backwards) {
	renderNoteProperties(true);
	renderActiveTaskProperties(false);
	renderNewTaskProperties(true);
	if (backwards) {
		renderFileToType(false);
		renderSummary(true);
	}
	renderErrorMessageOnPage(true, "");
}

export function newTaskScreen(backwards) {
	renderNewTaskProperties(false);
	renderNoteProperties(true);
	renderActiveTaskProperties(true);
	if (backwards) {
		renderFileToType(false);
		renderSummary(true);
	}
	renderErrorMessageOnPage(true, "");
	toggleLoading(false);
}

export function successScreen(hide, link, link_title, message) {
	renderLogo(false);
	renderSummary(true);
	renderSuccessMessage(hide, link, link_title, message);
	renderErrorMessageOnPage(true, "");
	renderExistingDocNote(true, null);
	toggleLoading(false);
}

export function errorScreen(
	hide: boolean,
	link: string,
	link_title: string,
	message: string,
	options?: {
		hideTitle: boolean;
		hideRetry: boolean;
		backTitle: string;
	}
) {
	renderLogo(false);
	renderSummary(true);
	renderExistingDocNote(true, null);
	renderErrorMessage(hide, link, link_title, message, options);
	renderErrorMessageOnPage(true, "");
	toggleLoading(false);
}

export function summaryScreen() {
	renderLogo(false);
	renderFileToType(true);
	renderNoteProperties(true);
	renderActiveTaskProperties(true);
	renderNewTaskProperties(true);
	renderSummary(false);
	renderErrorMessage(true, "", "", "");
	renderErrorMessageOnPage(true, "");
	toggleLoading(false);
}

export function throwError(message) {
	renderErrorMessageOnPage(false, message);
	toggleLoading(false);
}

export function clearError() {
	renderErrorMessageOnPage(true, null);
}

export function RefreshFieldsScreen() {
	renderLogo(false);
	renderEntityCriteria(true);
	renderSearchResults(true, []);
	renderFileToType(true);
	renderNoteProperties(true);
	renderActiveTaskProperties(true);
	renderNewTaskProperties(true);
	renderSummary(true);
	renderErrorMessage(true, "", "", "");
	renderErrorMessageOnPage(true, "");
	renderSuccessMessage(true, null, null, null);
	renderExistingDocNote(true, null);
	renderRefreshFields(false);
	toggleLoading(false);
}

export function ShowDone(closeButtonClick) {
	ReactDOM.render(
		<div className="ms-u-textAlignRight">
			<PrimaryButton data-automation-id="btnFile" text="File" onClick={closeButtonClick} />
		</div>,
		document.getElementById("done")
	);
}
