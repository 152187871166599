export function getLocationUrl() {
    const { protocol, hostname, port } = location;
    const strPort = port ? ":" + port : "";
    return `${protocol}//${hostname}${strPort}`;
}

export const config = {
    auth: {
        clientId: "83a242e4-c765-49b8-b38f-efa15c472760",
        authority: "https://login.microsoftonline.com/common",
        redirectUri: getLocationUrl() + "/msal-auth/redirect.html",
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    },
};

export const scopes = ["profile", "mail.read", "mail.read.shared"];

export const errorMessage =
    "Consent to the plugin reading your mailbox and basic profile (name, picture, user name); and then try again";

export const msalErrors = {
    12002: "The dialog box has been directed to a page that it cannot find or load, or the URL syntax is invalid.",
    12003: "The dialog box has been directed to a URL with the HTTP protocol. HTTPS is required.",
    12006: "The user closed the dialog instead of waiting for it to close.",
    13001: "No one is signed into Office. But you can use many of the add-ins functions anyway. If you want to log in, press the Get OneDrive File Names button again.",
    13002: "You can use many of the add-ins functions even though you have not granted consent. If you want to grant consent, press the Get OneDrive File Names button again.",
    13006: "Office on the web is experiencing a problem. Please sign out of Office, close the browser, and then start again.",
    13008: "Office is still working on the last operation. When it completes, try this operation again.",
    13010: "Follow the instructions to change your browser's zone configuration.",
};
