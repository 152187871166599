import axios, { AxiosError, AxiosResponse } from "axios";
import { detect } from "detect-browser";
import { throwError, failed_login } from "./navigationWorker";
import { LogError } from "../actions/errorWorker";
import * as querystring from "querystring-es3";
import { get_choice_field_dependence, has_capability } from "../actions/entityWorker";
import { DropdownMenuItemType } from "office-ui-fabric-react";
import { v4 as uuidv4 } from "uuid";
import { ApiResponseData, AppConfig } from "../types";
import { getAppConfig } from "../appConfig";

export const attachnmentTimeout =
	"Due to the large size of your email, this email will be filed in the background. Please check in Xplan to confirm successful filing of the email.";

const ERROR_MESSAGES = {
	"The remote server returned an error: (401) This feature is not available without a valid session.":
		"Session Expired. Please log back in.",
	"Error converting the response object of type System.Object from the Lambda function to JSON: Unable to expand length of this stream beyond its capacity.":
		attachnmentTimeout,
	"The remote server returned an error: (500) error deserializing result.":
		"The remote server returned an error: (500) error deserializing result.",
	'The remote server returned an error: (500) Schema node "choice_category_dependence": Required.':
		'The remote server returned an error: (500) Schema node "choice_category_dependence": Required.',
};

export function formatURL(url: string): string {
	if (url && url.endsWith("/")) {
		return url.slice(0, -1);
	}
	return url;
}

export function formatDate(date: Date): string {
	const monthNames = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

	const day = date.getDate();
	const monthIndex = date.getMonth();
	const year = date.getFullYear();

	return `${year}-${monthNames[monthIndex]}-${day}`;
}

export function formatDateTime(date: Date): string {
	const hour = date.getHours();
	const minute = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

	return `${formatDate(date)} ${hour}:${minute}`;
}

export function setXplanURL(url: string): void {
	try {
		localStorage.setItem("xplan_url", url);
	} catch (error) {
		throwError(error);
		return null;
	}
}

export function getXplanURL(): string {
	return formatURL(localStorage.getItem("xplan_url") || "");
}

export function setALBXplanCookie(val: string) {
	if (val.indexOf("XPLANID=") < 0) {
		const xplan_cookie = localStorage.getItem("xplan_cookie");
		const xplan_index = xplan_cookie.indexOf("XPLANID=");
		return `${val}${xplan_cookie.substring(xplan_index)}`;
	}

	return val;
}

export function setXplanCookie(value: string) {
	let tfaCookie = value.split(";").find((c) => c.startsWith("tfa-"));
	if (tfaCookie) {
		tfaCookie = tfaCookie += ";";
		localStorage.setItem("tfa_cookie", tfaCookie);
	}
	localStorage.setItem("xplan_cookie", value);
	const _1day = 24 * 60 * 60 * 1000;
	localStorage.setItem("xplan_cookie_expire", String(Date.now() + _1day));
}

export function getXplanCookie(): string {
	const e = new Date();
	if (e.getTime() < Number(localStorage.getItem("xplan_cookie_expire"))) {
		return localStorage.getItem("xplan_cookie");
	} else {
		localStorage.removeItem("xplan_cookie");
		localStorage.removeItem("xplan_cookie_expire");
		return null;
	}
}

export function getAuthType(): string {
	return localStorage.getItem("auth_token_type") || "Basic";
}

export function getoAuthData(): Record<string, string> {
	return {
		access_token: localStorage.getItem("access_token"),
		refresh_token: localStorage.getItem("refresh_token"),
		auth_token_type: localStorage.getItem("auth_token_type"),
	};
}

export function getHeaders(): object {
	const headers = {
		"Access-Control-Allow-Origin": "*",
		"Content-Type": "application/json",
	};
	if (localStorage.getItem("access_token")) {
		headers["Authorization"] = `bearer ${localStorage.getItem("access_token")}`;
	}
	return headers;
}

export function isEmpty(val: string) {
	return val ? val.length === 0 || /^\s+$/.test(val) : true;
}

export function base64encode(str: string) {
	if (isEmpty(str)) return str;
	const encode = encodeURIComponent(str).replace(/%([a-f0-9]{2})/gi, (_m, $1) =>
		String.fromCharCode(parseInt($1, 16))
	);
	return btoa(encode);
}

export function getPayload(url: string, auth = null, method = "GET") {
	const payload: { [key: string]: any } = {
		url,
		auth,
		method,
		data: {},
		cookie: getXplanCookie(),
		target: "XPLAN",
		source: "Outlook",
		user_id: getLoginUserId(),
		user_name: "",
		display_name: getDisplayName(),
		user_story_session_guid: getUserStorySessionGuid(),
		bucket_name: appConfig().bucket_name,
		aws_region: appConfig().awsRegion,
		environment: appConfig().environment,
		gmail_payload: {},
		...getLogData(),
	};

	if (getAuthType() !== "Basic") {
		payload["auth_type"] = getAuthType();
		payload["auth_data"] = getoAuthData();
	}

	return payload;
}

export function getLogData() {
	const browser = detect();
	const version = "0.0.0.0";
	const data: { [key: string]: any } = {
		is_shared_mailbox: isSharedMailbox(),
		is_desktop: Office?.context?.diagnostics?.platform
			? Office.context.diagnostics.platform !== Office.PlatformType.OfficeOnline
			: false,
		is_sso: localStorage.getItem("is_sso_login") === "true",
		office_platform: Office?.context?.mailbox?.diagnostics?.hostName || "",
		outlook_version: Office?.context?.mailbox?.diagnostics?.hostVersion || version,
		user_name: localStorage.getItem("user_name"),
	};

	if (browser) {
		data.browser_name = browser.name;
		data.browser_version = browser.version;
		data.os = browser.os;
	}

	return data;
}

export function isSharedMailbox() {
	if (!Office?.context?.mailbox?.item) return false;

	try {
		return !!Office.context.mailbox.item.getSharedPropertiesAsync;
	} catch {
		return false;
	}
}

export const APICalls = {
	Outlook: "https://outlook.office365.com/api/v2.0/me/messages",
	User: "/resourceful/session/user",
	EntitySearch: "/resourceful/entity/",
	DocnoteProperties: "/resourceful/docnote-v2/category",
	ActiveTasks:
		"/resourceful/task-v2?statuses.0=1000&statuses.1=2000&statuses.2=2500&statuses.3=3000{2}&list_option=all",
	FileEmail: "/resourceful/docnote-v2",
	FileEmailtoTask: "/resourceful/task-v2",
	Ufieldsearch: "/resourceful/ufield/",
	Capabilities: "/resourceful/session/capability",
	AWSUrl: (): string => {
		const result = appConfig();
		return result.aws_url || "";
	},
	AdviserSearch: "/resourceful/entity/user-v2?",
	FileToURL: "/resourceful/sysadmin/outlook_plugin",
	LogError: "/resourceful/logging/app",
	ExistingDocNote: "/resourceful/extdocnotelink",
	XplanVersion: "/resourceful/site",
	UserMessage: "/resourceful/user_message",
	LogoutXplan: "/resourceful/session",
	UserFieldDefaults: "/resourceful/sysadmin/outlook_plugin_settings",
	Oauth2Token: "/oauth2/token",
	MicrosoftGraph: "https://graph.microsoft.com",
};

export function IsOverlayShown(): boolean {
	return document.body.contains(document.getElementById("overlay"));
}

const mailboxErrors = ["ErrorMailboxMoveInProgress", "ErrorInvalidRequest", "ErrorItemNotFound"];

export function handleResponseData(
	response: AxiosResponse<ApiResponseData>,
	no_data_message: string,
	suppress_error = false
) {
	try {
		if (response?.data?.isError === true) {
			if (["Unauthorized", "401"].includes(response.data.statuscode)) {
				if (ERROR_MESSAGES[response.data.message]) {
					failed_login(ERROR_MESSAGES[response.data.message]);
					return null;
				}

				if (response.data.message.includes("TFA required"))
					return { message: "TFA Required", IsTFA: true };

				failed_login(response.data.message);
				return null;
			}

			if (response.data.statuscode === "InternalServerError") {
				LogError(response.data, {}, "", "ERROR");
				return response.data;
			}

			if (response.data.statuscode === "500" && ERROR_MESSAGES[response.data.message]) {
				LogError(response.data, {}, "", "ERROR");
				return null;
			}

			if (
				response.data.statuscode === "500" &&
				response.data.message.includes(
					'The remote server returned an error: (500) Schema node "'
				)
			) {
				LogError(response.data, {}, "", "ERROR");
				return {
					isError: true,
					message:
						"One or more Type/Subtype fields no longer exist. Please correct in Xplan.",
				};
			}

			if (response.data.statuscode === "404") {
				failed_login(response.data.message);
				return response.data;
			}

			if (mailboxErrors.includes(response.data.statuscode)) {
				LogError(response.data, {}, "MailboxError", "ERROR");
				return response.data;
			}

			const message = response.data.message || response.data.errorMessage;
			if (message) return { message, isError: true };

			LogError(response.data, {}, "Unhandled Error", "ERROR");
			return null;
		}

		if (response?.data?.data) {
			let parsed_data: any;
			try {
				parsed_data = JSON.parse(response.data.data);
			} catch (e) {
				parsed_data = response.data.attachments;
			}

			if (parsed_data.length === 0) throw new Error(no_data_message);
			return parsed_data;
		}

		if (response?.data?.errorMessage) {
			LogError(response, {}, "handleResponseData", "ERROR");
			const msg = ERROR_MESSAGES[response.data.errorMessage];

			if (msg === attachnmentTimeout) {
				return { message: attachnmentTimeout, isError: false };
			}

			if (msg) throw new Error(msg);
			throw new Error(response.data.errorMessage);
		}

		if (response?.data) return response.data;

		return null;
	} catch (error) {
		if (suppress_error) return null;
		throwError(error);
	}
}

export function getQueryParams(): querystring.ParsedUrlQuery {
	return querystring.parse(window.location.search.substring(1));
}

export const EntityTypesMap = (from) => {
	const types = {
		client: "client-v2",
		user: "user",
		referral: "referrer",
		supplier: "supplier",
		profadviser: "profadviser",
	};
	return types[from];
};

export function getEntityTypes(
	additional: Array<{ key: string; text: string; itemType: DropdownMenuItemType }>
): Array<{ key: string; text: string }> {
	const result = [
		{ key: "client-v2", text: "Client" },
		{ key: "user", text: "User" },
		{ key: "referrer", text: "Referrer" },
		{ key: "supplier", text: "Supplier" },
		{ key: "profadviser", text: "Professional Adviser" },
	];

	for (const add in additional) {
		if (add) {
			result.unshift(additional[add]);
		}
	}
	return result;
}

export function getEntityTypeText(key: string): string {
	key = key === "referral" ? "referrer" : key;
	key = key === "client" ? "client-v2" : key;
	const item = getEntityTypes(null).find((x) => {
		return x.key === key;
	});
	if (!item) {
		return "Client";
	}
	return item.text;
}

export function GetDependOptions(
	groupName: string,
	parentValue: string
): Array<{ key: string; text: string }> {
	get_choice_field_dependence(groupName, "subtype", "");
	const data = JSON.parse(localStorage.getItem(groupName + "_subtype_results")) || [];
	const mapping = JSON.parse(localStorage.getItem(groupName + "_subtype_depend_results")) || "";

	if (mapping?.choice_category_dependence) {
		const dependOptions = mapping.choice_category_dependence;
		if (
			dependOptions !== null &&
			dependOptions[parentValue] !== null &&
			dependOptions[parentValue] !== undefined
		) {
			const subOptions = dependOptions[parentValue];
			const result = [];
			data.filter((x) => subOptions.includes(x[0].toString())).map((z) =>
				result.push({ key: z[0], text: z[1] })
			);
			return result;
		}

		const result = [];
		data.map((z) => result.push({ key: z[0], text: z[1] }));
		return result;
	}

	const result = [];
	data.map((z) => result.push({ key: z[0], text: z[1] }));
	return result;
}

export function GetItemText(listName: string, key: string): string {
	const data = JSON.parse(localStorage.getItem(listName)) || [];

	try {
		return data.find((x) => x[0].toString() === key)[1];
	} catch (error) {
		return "";
	}
}

export function SetPermissionList(): void {
	const permissions = [];
	if (has_capability("docnote_shareprivate")) {
		permissions.push(["0", "Private"]);
	}
	if (has_capability("docnote_sharemygroups")) {
		permissions.push(["1", "Shared With My Groups"]);
	}
	if (has_capability("docnote_shareall")) {
		permissions.push(["2", "Shared With All"]);
	}
	if (has_capability("docnote_sharespecgroups")) {
		permissions.push(["3", "Shared With Specific Groups"]);
	}
	if (has_capability("docnote_shareclientgroups")) {
		permissions.push(["4", "Shared With Client Groups"]);
	}
	localStorage.setItem("PermissionList", JSON.stringify(permissions));
}

export function getLoginUserId(): string {
	return localStorage.getItem("loginUserId") || "";
}

export function getDisplayName(): string {
	return localStorage.getItem("loginUserName") || "";
}

export function getLoginUserName(): string {
	return localStorage.getItem("xplan_user_name") || "";
}

export function getAppClientID(): string {
	const result = appConfig();
	return result.app_client_id;
}

export function getRedirectURL(): string {
	const result = appConfig();
	return result.redirect_url;
}

export function getXplanTokenURL(): string {
	return getXplanURL() + APICalls.Oauth2Token;
}

export function getEnvironment(): string {
	return appConfig().environment;
}

function escapeRegExp(strToEscape: string): string {
	return strToEscape.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}

export function trimChar(origString: string, charToTrim: string): string {
	charToTrim = escapeRegExp(charToTrim);
	const regEx = new RegExp(`^[${charToTrim}]+|[${charToTrim}]+$`, "g");
	return origString.replace(regEx, "");
}

export const httpMethods = {
	Get: "GET",
	Post: "POST",
	Patch: "PATCH",
	Delete: "DELETE",
};

export function appConfig(): AppConfig {
	const config = localStorage.getItem("config");
	return config ? JSON.parse(config) : getAppConfig();
}

export function setConfig(config: object): void {
	localStorage.setItem("config", JSON.stringify(config));
}

export function setAxiosDefaults(): void {
	axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
	axios.defaults.headers.common["Content-Type"] = "application/json";
}

export function watchForAuthCode(): void {
	const timer = setInterval(() => {
		const uss_guid = localStorage.getItem("user_story_session_guid");
		if (uss_guid !== null) {
			axios
				.get(`${uss_guid}.json`)
				.then((res) => JSON.parse(res.data))
				.then((data) => {
					if (data.auth_code !== null) localStorage.setItem("auth_code", data.auth_code);
					if (data.access_token !== null)
						localStorage.setItem("access_token", data.access_token); // Access token expires in one hour.
					if (data.refresh_token !== null)
						localStorage.setItem("refresh_token", data.refresh_token); // Refresh token that can be used to exchange for a new access token
					if (data.token_type !== null)
						localStorage.setItem("auth_token_type", data.token_type);
					if (data.expires_in !== null)
						localStorage.setItem("expires_in", data.expires_in);
				})
				.catch((err) => {
					if (err?.response?.status !== 404) clearInterval(timer);
				});
		}

		setTimeout(() => {
			const ac = localStorage.getItem("auth_code");
			if (ac !== null) {
				AddAuthCodeToCookie(ac);
				location.reload();
			}
		}, 500);
	}, 2000);

	setTimeout(() => {
		clearInterval(timer);
	}, 120000); // 2 min
}

export function validateSiteUrl(url: string, strict = true) {
	try {
        const parts = new URL(url);
        if (!strict) return true;
        return !(
            parts.search ||
            parts.protocol !== "https:"
        );
    } catch (_) {
        return false;
    }
}

export function exampleUrl() {
	const url = new URL(document.URL);
	const [_, b] = url.host.split("iress.");
	return `https://sitename.xplan.iress.${b || "co.za"}`;
}

export function AddAuthCodeToCookie(authCode: string): void {
	let cookie = getXplanCookie();
	if (!cookie) cookie = "";
	if (cookie.includes("authCode=")) {
		cookie = cookie.substring(0, cookie.indexOf("authCode="));
	}
	cookie = `${cookie}authCode=${authCode}`;
	setXplanCookie(cookie);
}

export function clearLogin(): void {
	localStorage.removeItem("loginUserId");
	localStorage.removeItem("loginUserName");
	localStorage.removeItem("xplan_cookie");
	localStorage.removeItem("access_token");
	localStorage.removeItem("refresh_token");
	localStorage.removeItem("auth_token_type");
	localStorage.removeItem("capabilities_results");
	localStorage.removeItem("entity_entity_client_status_default");
	localStorage.removeItem("entity_note_type_default");
	localStorage.removeItem("entity_note_subtype_depend_default");
	localStorage.removeItem("Permission_default");
	localStorage.removeItem("task_type_default");
	localStorage.removeItem("task_subtype_depend_default");
	localStorage.removeItem("task_status_default");
	localStorage.removeItem("skip_summary");
	localStorage.removeItem("hide_postal_code");
	localStorage.removeItem("hide_dob");
}

export function getUserStorySessionGuid(): string {
	if (localStorage.getItem("user_story_session_guid") === null) {
		localStorage.setItem("user_story_session_guid", uuidv4());
	}
	return localStorage.getItem("user_story_session_guid");
}

export function getDeploymentId(env = "production") {
	switch (env) {
		case "production":
			return "AKfycbw5zhAB3Ylcky5NBi48YKqtmMHNzJTG_CrdH4mcTEBYMFqltxEEdcGfm4nENnVFUF7J";
		case "staging":
			return "AKfycbycp6Ugj6UH5-FolkQeQVKGG_DA6NZkq0fntnpgSaOPyY0EXQw0URtG7I2NamNoU92KDw";
		default:
			return "";
	}
}

export function isNetworkError(err: any) {
	return !!err.isAxiosError && !err.response;
}

export function handleAxiosError(error: AxiosError) {
	if (!error.isAxiosError) throw new Error("Expected an axios error object");
	return error.response.data;
}

export function updateCredentials(response: AxiosResponse<any>, data: any) {
	const cookie = response?.data?.xplan_cookie;
	if (cookie) {
		const new_cookie = setALBXplanCookie(cookie);
		if (data?.entity_id) localStorage.setItem("loginUserId", data.entity_id);
		if (data?.entity_name) localStorage.setItem("xplan_user_name", data.entity_name);
		setXplanCookie(new_cookie);
	}
}

export function setEmailAddress(email: string) {
	localStorage.setItem("emailAddress", email);
}

export function getEmailAddress() {
	return localStorage.getItem("emailAddress");
}

export function setGraphAccessToken(accessToken: string) {
	if (!accessToken) return;

	const date = new Date();
	date.setMinutes(date.getMinutes() + 55);

	const token = {
		account: Office.context.mailbox.userProfile.emailAddress,
		accessToken,
		ttl: date.getTime(),
	};

	localStorage.setItem("msalAccessToken", JSON.stringify(token));
}

export function getGraphAccessToken() {
	try {
		const token = JSON.parse(localStorage.getItem("msalAccessToken"));

		if (
			!token ||
			Office.context.mailbox.userProfile.emailAddress !== token.account ||
			token.ttl < Date.now()
		) {
			localStorage.removeItem("msalAccessToken");
			return null;
		}

		return token.accessToken;
	} catch {
		return null;
	}
}

export function cleanup() {
	localStorage.clear();
	sessionStorage.clear();
	location.reload();
}
