import * as React from 'react';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { List } from 'office-ui-fabric-react/lib/List';
import { Spinner } from 'office-ui-fabric-react/lib/Spinner';
import { fileToTypeScreen, throwError, notesScreen, activeTaskScreen, newTaskScreen, toggleLoading } from '../actions/navigationWorker';
import { LogError } from '../actions/errorWorker';
import { getEntityTypeText } from '../actions/helper';
import { fileEmail } from '../actions/FilerWorker';

export interface AppProps {
    hide: boolean;
}

export interface AppState {
    selected_clients: any,
    file_to_type: any,
    doc_category: any,
    doc_type: any,
    doc_subtype: any,
    doc_permission: any,
    doc_access: any,
    active_tasks: any,
    subject: any,
    task_type: any,
    task_subtype: any,
    task_status: any,
    loginUserName: string
}

class Summary extends React.Component<AppProps, AppState> {
    constructor(props) {
        super(props);
        this.get_storage_data();
    }

    get_storage_data = () => {
        const not_selected = { id: 'not_selected', name: '<NOT SELECTED>' };
        const selected_clients = JSON.parse(sessionStorage.getItem('selected_clients')) || [];
        const file_to_type = JSON.parse(sessionStorage.getItem('file_to_type')) || {};
        const doc_category = JSON.parse(sessionStorage.getItem('doc_category')) || [not_selected];
        const doc_type = JSON.parse(sessionStorage.getItem('doc_type')) || {};
        const doc_subtype = JSON.parse(sessionStorage.getItem('doc_subtype'));
        const doc_permission = JSON.parse(sessionStorage.getItem('doc_permission')) || {};
        const doc_access = JSON.parse(sessionStorage.getItem('doc_access')) || [not_selected];
        const active_tasks = JSON.parse(sessionStorage.getItem('selected_active_tasks')) || [];
        const subject = sessionStorage.getItem('subject');
        const task_type = JSON.parse(sessionStorage.getItem('task_type')) || {};
        const task_subtype = JSON.parse(sessionStorage.getItem('task_subtype')) || not_selected;
        const task_status = JSON.parse(sessionStorage.getItem('task_status')) || {};
        const loginUserName = localStorage.getItem('loginUserName') || '';
        this.state = {
            selected_clients: selected_clients,
            file_to_type: file_to_type,
            doc_category: doc_category.length === 0 ? [not_selected] : doc_category,
            doc_type: doc_type,
            doc_subtype: doc_subtype ? doc_subtype : not_selected,
            doc_permission: doc_permission,
            doc_access: doc_access.length === 0 ? [not_selected] : doc_access,
            active_tasks: active_tasks,
            subject: subject,
            task_type: task_type,
            task_subtype: task_subtype,
            task_status: task_status,
            loginUserName: loginUserName
        };
    }

    back_to_file_type = () => {
        if (this.state.file_to_type.id === 'note') {
            notesScreen(true);
        } else if (this.state.file_to_type.id === 'activetask') {
            activeTaskScreen(true);
        } else if (this.state.file_to_type.id === 'newtask') {
            newTaskScreen(true);
        } else {
            fileToTypeScreen(true);
        }
    }

    fileEmailClick = async () => {
        toggleLoading(true);
        let otherData = {};
        
        try {
            let selected_eIds = [];
            let selected_cats = [];
            let client_access = false;
            let referrer_access = false;
            let profadviser_access = false;

            for (let i = 0; i < this.state.selected_clients.length; i++) {
                selected_eIds.push((this.state.selected_clients[i] as any).id);
            }
            let state_selected_cats = JSON.parse(sessionStorage.getItem('doc_category')) || [];
            for (let i = 0; i < state_selected_cats.length; i++) {
                selected_cats.push((this.state.doc_category[i] as any).id);
            }
            let state_access = JSON.parse(sessionStorage.getItem('doc_access')) || [];
            for (let i = 0; i < state_access.length; i++) {
                let access_id = (state_access[i] as any).id;
                if (access_id === 'is_client_access_accessible') {
                    client_access = true;
                } else if (access_id === 'is_referrer_accessible') {
                    referrer_access = true;
                } else if (access_id === 'is_profadviser_accessible') {
                    profadviser_access = true;
                }
            }

            let file_to_type = JSON.parse(sessionStorage.getItem('file_to_type'));
            if (file_to_type.id === 'note') {
                otherData = {
                    subject: this.state.subject,
                    doctype: this.state.doc_type.id,
                    entity_ids: selected_eIds,
                    permission: this.state.doc_permission.name,
                    categories: selected_cats,
                    is_client_access_accessible: client_access,
                    is_referrer_accessible: referrer_access,
                    is_profadviser_accessible: profadviser_access,
                    username: this.state.loginUserName,
                    fileToType: file_to_type.id,
                    role: sessionStorage.getItem('selected_entity_type')
                };
                if (sessionStorage.getItem('doc_subtype')) {
                    const subtype = JSON.parse(sessionStorage.getItem('doc_subtype'))
                    if (subtype.id && subtype.id !== 'null') {
                        otherData['docsubtype'] = subtype.id;
                    }
                }
            } else if (file_to_type.id === 'newtask') {
                otherData = {
                    subject: this.state.subject,
                    tasktype: this.state.task_type.id,
                    tasksubtype: this.state.task_subtype.id,
                    entity_ids: selected_eIds,
                    taskstatus: this.state.task_status.id,
                    username: this.state.loginUserName,
                    fileToType: file_to_type.id,
                    role: sessionStorage.getItem('selected_entity_type')
                };
            } else if (file_to_type.id === 'activetask') {
                otherData = {
                    entity_ids: selected_eIds,
                    username: this.state.loginUserName,
                    fileToType: file_to_type.id,
                    role: sessionStorage.getItem('selected_entity_type')
                };
            }

            await fileEmail(otherData);
        } catch (err) {
            throwError(err.message);
            LogError(err, { otherData }, 'fileEmailClick', 'ERROR');
        }
    }

    public render() {
        if (this.props.hide) {
            return (null);
        }

        this.get_storage_data();
        if (localStorage.getItem('skip_summary') === 'true') {
            this.fileEmailClick();
            return (<Spinner className='middle loadingSpinner' label='Filing...' />);
        }

        if (this.state.file_to_type.id === 'note') {
            return (
                <div>
                    <p>If the details below are correct, please file email to Xplan:</p>
                    <div className='ms-Grid'>
                        <div className='ms-Grid-row'>
                            <div className='ms-Grid-col ms-sm4 ms-md4 ms-lg4'>
                                Selected {getEntityTypeText(sessionStorage.getItem('selected_entity_type'))}(s):
                            </div>
                            <div className='ms-Grid-col ms-sm8 ms-md8 ms-lg8'>
                                <List items={this.state.selected_clients} />
                            </div>
                        </div>
                        <br />
                        <div className='ms-Grid-row'>
                            <div className='ms-Grid-col ms-sm4 ms-md4 ms-lg4'>
                                File To:
                            </div>
                            <div className='ms-Grid-col ms-sm8 ms-md8 ms-lg8'>
                                {this.state.file_to_type.name}
                            </div>
                        </div>
                        <br />
                        <div className='ms-Grid-row'>
                            <div className='ms-Grid-col ms-sm4 ms-md4 ms-lg4'>
                                Subject:
                            </div>
                            <div className='ms-Grid-col ms-sm8 ms-md8 ms-lg8'>
                                {this.state.subject}
                            </div>
                        </div>
                        <br />
                        <div className='ms-Grid-row'>
                            <div className='ms-Grid-col ms-sm4 ms-md4 ms-lg4'>
                                Type:
                            </div>
                            <div className='ms-Grid-col ms-sm8 ms-md8 ms-lg8'>
                                {this.state.doc_type.name}
                            </div>
                        </div>
                        <br />
                        <div className='ms-Grid-row'>
                            <div className='ms-Grid-col ms-sm4 ms-md4 ms-lg4'>
                                Subtype:
                            </div>
                            <div className='ms-Grid-col ms-sm8 ms-md8 ms-lg8'>
                                {this.state.doc_subtype.name}
                            </div>
                        </div>
                        <br />
                        <div className='ms-Grid-row'>
                            <div className='ms-Grid-col ms-sm4 ms-md4 ms-lg4'>
                                Permission:
                            </div>
                            <div className='ms-Grid-col ms-sm8 ms-md8 ms-lg8'>
                                {this.state.doc_permission.name}
                            </div>
                        </div>
                        <br />
                        <div className='ms-Grid-row'>
                            <div className='ms-Grid-col ms-sm4 ms-md4 ms-lg4'>
                                Access:
                            </div>
                            <div className='ms-Grid-col ms-sm8 ms-md8 ms-lg8'>
                                <List items={this.state.doc_access} />
                            </div>
                        </div>
                        <br />
                        <div className='ms-Grid-row'>
                            <div className='ms-Grid-col ms-sm4 ms-md4 ms-lg4'>
                                Category:
                            </div>
                            <div className='ms-Grid-col ms-sm8 ms-md8 ms-lg8'>
                                <List items={this.state.doc_category} />
                            </div>
                        </div>
                    </div>
                    <br />
                    <div>
                        <div className='footerButton ms-Grid-col ms-sm6 ms-md6 ms-lg6'>
                            <DefaultButton data-automation-id='btnBack' text='Back' onClick={this.back_to_file_type} />
                        </div>
                        <div className='footerButton ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-u-textAlignRight'>
                            <PrimaryButton data-automation-id='btnNext' text='File' onClick={this.fileEmailClick} />
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.file_to_type.id === 'activetask') {
            return (
                <div>
                    <p>If the details below are correct, please file email to Xplan:</p>
                    <div className='ms-Grid'>
                        <div className='ms-Grid-row'>
                            <div className='ms-Grid-col ms-sm4 ms-md4 ms-lg4'>
                                Selected {getEntityTypeText(sessionStorage.getItem('selected_entity_type'))}(s):
                            </div>
                            <div className='ms-Grid-col ms-sm8 ms-md8 ms-lg8'>
                                <List items={this.state.selected_clients} />
                            </div>
                        </div>
                        <br />
                        <div className='ms-Grid-row'>
                            <div className='ms-Grid-col ms-sm4 ms-md4 ms-lg4'>
                                File To:
                            </div>
                            <div className='ms-Grid-col ms-sm8 ms-md8 ms-lg8'>
                                {this.state.file_to_type.name}
                            </div>
                        </div>
                        <br />
                        <div className='ms-Grid-row'>
                            <div className='ms-Grid-col ms-sm4 ms-md4 ms-lg4'>
                                Active Tasks:
                            </div>
                            <div className='ms-Grid-col ms-sm8 ms-md8 ms-lg8'>
                                <List items={this.state.active_tasks} />
                            </div>
                        </div>
                    </div>
                    <br />
                    <div>
                        <div className='footerButton ms-Grid-col ms-sm6 ms-md6 ms-lg6'>
                            <DefaultButton data-automation-id='btnBack' text='Back' onClick={this.back_to_file_type} />
                        </div>
                        <div className='footerButton ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-u-textAlignRight'>
                            <PrimaryButton data-automation-id='btnNext' text='File' onClick={this.fileEmailClick} />
                        </div>
                    </div>
                </div>
            );
        } else if (this.state.file_to_type.id === 'newtask') {
            return (
                <div>
                    <p>If the details below are correct, please file email to Xplan:</p>
                    <div className='ms-Grid'>
                        <div className='ms-Grid-row'>
                            <div className='ms-Grid-col ms-sm4 ms-md4 ms-lg4'>
                                Selected {getEntityTypeText(sessionStorage.getItem('selected_entity_type'))}(s):
                            </div>
                            <div className='ms-Grid-col ms-sm8 ms-md8 ms-lg8'>
                                <List items={this.state.selected_clients} />
                            </div>
                        </div>
                        <br />
                        <div className='ms-Grid-row'>
                            <div className='ms-Grid-col ms-sm4 ms-md4 ms-lg4'>
                                File To:
                            </div>
                            <div className='ms-Grid-col ms-sm8 ms-md8 ms-lg8'>
                                {this.state.file_to_type.name}
                            </div>
                        </div>
                        <br />
                        <div className='ms-Grid-row'>
                            <div className='ms-Grid-col ms-sm4 ms-md4 ms-lg4'>
                                Subject:
                            </div>
                            <div className='ms-Grid-col ms-sm8 ms-md8 ms-lg8'>
                                {this.state.subject}
                            </div>
                        </div>
                        <br />
                        <div className='ms-Grid-row'>
                            <div className='ms-Grid-col ms-sm4 ms-md4 ms-lg4'>
                                Type:
                            </div>
                            <div className='ms-Grid-col ms-sm8 ms-md8 ms-lg8'>
                                {this.state.task_type.name}
                            </div>
                        </div>
                        <br />
                        <div className='ms-Grid-row'>
                            <div className='ms-Grid-col ms-sm4 ms-md4 ms-lg4'>
                                Subtype:
                            </div>
                            <div className='ms-Grid-col ms-sm8 ms-md8 ms-lg8'>
                                {this.state.task_subtype.name}
                            </div>
                        </div>
                        <br />
                        <div className='ms-Grid-row'>
                            <div className='ms-Grid-col ms-sm4 ms-md4 ms-lg4'>
                                Status:
                            </div>
                            <div className='ms-Grid-col ms-sm8 ms-md8 ms-lg8'>
                                {this.state.task_status.name}
                            </div>
                        </div>
                    </div>
                    <br />
                    <div>
                        <div className='footerButton ms-Grid-col ms-sm6 ms-md6 ms-lg6'>
                            <DefaultButton data-automation-id='btnBack' text='Back' onClick={this.back_to_file_type} />
                        </div>
                        <div className='footerButton ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-u-textAlignRight'>
                            <PrimaryButton data-automation-id='btnNext' text='File' onClick={this.fileEmailClick} />
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <p>No summary available.</p>
                    <div >
                        <div className='footerButton ms-Grid-col ms-sm6 ms-md6 ms-lg6'>
                            <DefaultButton data-automation-id='btnBack' text='Back' onClick={this.back_to_file_type} />
                        </div>
                        <div className='footerButton ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-u-textAlignRight'>
                            <PrimaryButton data-automation-id='btnNext' text='File' onClick={this.fileEmailClick} />
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default Summary;
