import * as React from 'react';
import { DetailsList, DetailsListLayoutMode, IColumn, Selection, SelectionMode, CheckboxVisibility } from 'office-ui-fabric-react/lib/DetailsList';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { entityResultsScreen, summaryScreen, toggleLoading } from '../actions/navigationWorker';

export interface AppProps {
    hide: boolean;
    data: Array<JSON>;
}

export interface AppState {
    columns: IColumn[],
    selected: any,
    data: any
}

class ActiveTaskProperties extends React.Component<AppProps, AppState> {
    private _selection: Selection;

    constructor(props) {
        super(props);

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selected: this._getSelectionDetails()
                });
            }
        });

        this.state = {
            columns: this._getColumns(),
            selected: null,
            data: this.props.data
        };
    }

    back_to_results = () => {
        let results = JSON.parse(sessionStorage.getItem('search_results')) || [];
        entityResultsScreen(true, results);
    }

    private _getColumns(): IColumn[] {
        let columns = [];
        const fields = [{ field: 'details', header: 'Task Details', min: 80, 'max': 200 }];

        for (let i = 0; i < fields.length; i++) {
            let field = fields[i];
            let item_column = {
                key: 'col_' + field['field'],
                name: field['header'],
                fieldName: field['field'],
                minWidth: field['min'],
                maxWidth: field['max'],
                isResizable: true
            };
            columns.push(item_column);
        }
        return columns;
    }

    private _getSelectionDetails(): any {
        const selected_items = this._selection.getSelection();
        const selected_tasks = [];

        if (selected_items !== null) {
            for (let i = 0; i < selected_items.length; i++) {
                let task_id = (selected_items[i] as any).id;
                let task_subject = (selected_items[i] as any).subject;
                let selected_task = { id: task_id, name: task_subject };
                selected_tasks.push(selected_task);
            }
        }
        return selected_tasks.length > 0 ? selected_tasks : null;
    }

    summary = () => {
        toggleLoading(true);
        const task_ids = this._getSelectionDetails();
        sessionStorage.setItem('selected_active_tasks', JSON.stringify(task_ids));
        summaryScreen();
    }


    private _renderItemColumn(item: any, _: number, column: IColumn) {
        const fieldContent = item[column.fieldName || ''];
        const break_string = '|break|';
        if (column.key === 'col_details') {
            return fieldContent.split(break_string).map(t => <span key={t}>{t}<br /></span>);
        } else {
            return <span>{fieldContent}</span>;
        }
    }

    public render() {
        if (this.props.hide) {
            return (null);
        }
        toggleLoading(false);
        if (this.props.data.length === 0) {
            return (
                <div>
                    <div>
                        <div className='footerButton ms-Grid-col ms-sm6 ms-md6 ms-lg6'>
                            <DefaultButton data-automation-id='btnBack' text='Back' onClick={this.back_to_results} />
                        </div>
                        <div className='footerButton ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-u-textAlignRight'>
                            <PrimaryButton data-automation-id='btnNext' disabled={!this.state.selected} text='Next' onClick={this.summary} />
                        </div>
                    </div>
                    <div className='ms-u-textAlignCenter'>
                        <br /><br /><br /><br />
                        <div style={{ color: 'red' }}>No Active tasks found</div>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <p>Please select the active tasks that you would like to file the current email to:</p>
                <div style={{ overflow: 'auto' }} data-is-scrollable='true'>
                    <MarqueeSelection selection={this._selection}>
                        <DetailsList
                            items={this.props.data}
                            columns={this._getColumns()}
                            setKey='set'
                            selectionMode={SelectionMode.multiple}
                            layoutMode={DetailsListLayoutMode.fixedColumns}
                            selectionPreservedOnEmptyClick={true}
                            enterModalSelectionOnTouch={true}
                            checkboxVisibility={CheckboxVisibility.always}
                            isHeaderVisible={true}
                            compact={true}
                            selection={this._selection}
                            onRenderItemColumn={this._renderItemColumn}
                        />
                    </MarqueeSelection>
                </div>
                <br />
                <div>
                    <div className='footerButton ms-Grid-col ms-sm6 ms-md6 ms-lg6'>
                        <DefaultButton data-automation-id='btnBack' text='Back' onClick={this.back_to_results} />
                    </div>
                    <div className='footerButton ms-Grid-col ms-sm6 ms-md6 ms-lg6 ms-u-textAlignRight'>
                        <PrimaryButton data-automation-id='btnNext' disabled={!this.state.selected} text='Next' onClick={this.summary} />
                    </div>
                </div>
            </div>
        );
    }
}

export default ActiveTaskProperties;
