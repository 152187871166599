import axios from "axios";
import { APICalls, getXplanURL, getHeaders, getPayload, getLoginUserId } from "./helper";

export function LogError(errors, request, source, severity = "ERROR") {
    if (!getLoginUserId()) return;
    const error_message = source === "handleResponseData" ? errors.data.data : errors.message;

    const config = { headers: getHeaders() };
    const logRequest = request;
    const hidden = '*** hidden ***';

    if (logRequest) {
        logRequest.config = config;
        if (logRequest.config.headers?.Authorization) logRequest.config.headers.Authorization = hidden;
        if (logRequest?.payload?.body) logRequest.payload.body = hidden;
        if (logRequest?.payload?.auth) logRequest.payload.auth = hidden;
        if (logRequest?.payload?.auth_data) logRequest.payload.auth_data = hidden;
        if (logRequest?.auth) logRequest.auth = hidden;
        if (logRequest?.payload?.attachmentToken) logRequest.payload.attachmentToken = hidden;
        if (logRequest?.payload?.accessToken) logRequest.payload.accessToken = hidden;
        if (logRequest?.accessToken) logRequest.payload.accessToken = hidden;
        if (logRequest?.payload?.data?.content) logRequest.payload.data.content = hidden;
        if (logRequest?.data?.content) logRequest.data.content = hidden;
        if (logRequest?.data?.data?.content) logRequest.data.data.content = hidden;
        if (logRequest?.payload?.data?.body) logRequest.payload.data.body = hidden;
        if (logRequest?.payload?.cookie) logRequest.payload.cookie = hidden;
    }

    const payload = getPayload(getXplanURL() + "/DATADOGLOGGING", null, "POST");
    payload.data = {
        message: error_message,
        severity: severity,
        source_function: source,
        request: logRequest,
        user_story_session_guid: localStorage.getItem("user_story_session_guid"),
    };
    payload.target = "DataDog";
    axios.post(APICalls.AWSUrl(), payload).catch(() => { })
}
