import * as React from 'react';

function Splash() {
    React.useEffect(() => {
        const container = document.getElementById('MainBody');
        if (container) container.style.backgroundColor = '#3a1c46';
    },[])
    

    return(
        <div className='ms-u-textAlignCenter'>
            <img src='assets/main_logo.png' style={{marginTop: '40%', height: '90%'}}></img>
        </div>
    );
}

export default React.memo(Splash);
