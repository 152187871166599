
export function GetSetting(name, data = null) {
    const settingValue = localStorage.getItem(name);
    if (data && settingValue && !data.includes(settingValue)) {
        return null;
    }
    return settingValue;
}

export function RemoveSetting(name, defaultValue) {
    let setting = localStorage.getItem(name) ;
    let setting_array = setting === null ? [] : setting.split(',').map(Number);
    for (let i = setting_array.length; i--;) {
        if (setting_array[i] === defaultValue) {
            setting_array.splice(i, 1);
        }
    }
    localStorage.setItem(name, setting_array.toString());
}

export function SetSetting(name, defaultValue) {
    if (defaultValue === null) {
        localStorage.removeItem(name);
    }
    else {
        if (name === 'entity_entity_client_status_default') {
            let client_status = localStorage.getItem(name) ;
            let setting = client_status === null ? [] : client_status.split(',');
            setting.push(defaultValue);
            localStorage.setItem(name, setting.toString());

        }
        else {
            localStorage.setItem(name, defaultValue);
        }
    }
}
