import * as React from 'react';

type Props = {
    error: string|Error;
    hide: boolean
}
export default function ErrorMessageOnPage({ hide, error }: Props) {
    if (hide === true) return null;

    const errorMessage = typeof(error) === "string" ? error : error.message;
    
    return (
        <div className='ms-u-textAlignCenter'>
            <br /><br />
            <div style={{ color: 'red' }}>{errorMessage}</div>
        </div>
    );
}
